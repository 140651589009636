import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { store } from './store';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';

import '@payaca/styles';
import './index.sass';

import App from './components/App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_CLIENT_ID,
    environment: process.env.REACT_APP_NODE_ENV || process.env.NODE_ENV,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
