import React from 'react';
import './Button.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
export var ButtonStyleVariant;
(function (ButtonStyleVariant) {
    ButtonStyleVariant["STANDARD"] = "standard";
    ButtonStyleVariant["OUTSIZE"] = "outsize";
    ButtonStyleVariant["ANCHOR"] = "anchor";
    ButtonStyleVariant["CIRCULAR"] = "circular";
})(ButtonStyleVariant || (ButtonStyleVariant = {}));
export var ButtonColourVariant;
(function (ButtonColourVariant) {
    ButtonColourVariant["PRIMARY"] = "primary";
    ButtonColourVariant["SECONDARY"] = "secondary";
    ButtonColourVariant["WHITE"] = "white";
    ButtonColourVariant["GREY"] = "grey";
})(ButtonColourVariant || (ButtonColourVariant = {}));
var Button = function (_a) {
    var _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, children = _a.children, iconBefore = _a.iconBefore, iconAfter = _a.iconAfter, onClick = _a.onClick, onClickDisabled = _a.onClickDisabled, _c = _a.isProcessing, isProcessing = _c === void 0 ? false : _c, _d = _a.styleVariant, styleVariant = _d === void 0 ? ButtonStyleVariant.STANDARD : _d, _e = _a.colourVariant, colourVariant = _e === void 0 ? ButtonColourVariant.SECONDARY : _e, _f = _a.hasBoxShadow, hasBoxShadow = _f === void 0 ? false : _f, _g = _a.type, type = _g === void 0 ? 'button' : _g, className = _a.className, _h = _a.isOutlined, isOutlined = _h === void 0 ? false : _h;
    return (React.createElement("button", { className: (className ? className + " " : '') + "button v2 button-style-" + styleVariant + " button-col-" + colourVariant + " " + (isDisabled ? 'disabled' : '') + " " + (isProcessing ? 'processing' : '') + " " + (hasBoxShadow ? 'box-shadow' : '') + " " + (isOutlined ? 'outlined' : ''), onClick: isDisabled ? onClickDisabled : onClick, disabled: isDisabled && !onClickDisabled, type: type },
        React.createElement(React.Fragment, null,
            iconBefore && (React.createElement("span", { className: "icon" }, React.isValidElement(iconBefore) ? (iconBefore) : (
            // @ts-ignore
            React.createElement(FontAwesomeIcon, { icon: iconBefore })))),
            isProcessing && (React.createElement("span", { className: 'spinner-container' },
                React.createElement(FontAwesomeIcon, { icon: faCircleNotch, spin: true }))),
            React.createElement("span", { className: "button-content" }, children),
            iconAfter && (React.createElement("span", { className: "icon" }, React.isValidElement(iconAfter) ? (iconAfter) : (
            // @ts-ignore
            React.createElement(FontAwesomeIcon, { icon: iconAfter })))))));
};
export default Button;
