/**
 * JobOverviewPage
 * - Used for pdf generation
 */
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { LoaderOverlay, JobOverviewBusinessDetails, JobOverviewFooter, } from '@payaca/components';
import { JobOverview } from '@payaca/components/jobOverview';
import { JobOverviewHeader } from '@payaca/components/jobOverviewHeader';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import agent from '../../agent';
import './JobOverviewPage.sass';
var FOOTER_SIZES;
(function (FOOTER_SIZES) {
    FOOTER_SIZES[FOOTER_SIZES["small"] = 0] = "small";
    FOOTER_SIZES[FOOTER_SIZES["medium"] = 1] = "medium";
    FOOTER_SIZES[FOOTER_SIZES["large"] = 2] = "large";
})(FOOTER_SIZES || (FOOTER_SIZES = {}));
var JobOverviewPage = function () {
    var previewToken = getUrlSearchParam(window.location.search, 'previewToken');
    var shouldAutoPrint = getUrlSearchParam(window.location.search, 'print') === 'true';
    var _a = useState(), job = _a[0], setJob = _a[1];
    var _b = useState(), customer = _b[0], setCustomer = _b[1];
    var _c = useState(), jobFinanceInformation = _c[0], setJobFinanceInformation = _c[1];
    var _d = useState(), satisfactionNoteSignatureImageData = _d[0], setSatisfactionNoteSignatureImageData = _d[1];
    var _e = useState(), account = _e[0], setAccount = _e[1];
    var _f = useState(), jobPayments = _f[0], setJobpayments = _f[1];
    var _g = useState(), acceptedSignatureImageData = _g[0], setAcceptedSignatureImageData = _g[1];
    var _h = useState(), jobLineItems = _h[0], setJobLineItems = _h[1];
    var _j = useState(), jobLineItemAttachments = _j[0], setJobLineItemAttachments = _j[1];
    var _k = useState(), jobLineItemGroups = _k[0], setJobLineItemGroups = _k[1];
    var _l = useState(), error = _l[0], setError = _l[1];
    var _m = useState(false), isLoading = _m[0], setIsLoading = _m[1];
    useEffect(function () {
        setIsLoading(true);
        agent.Auth.getJobOvervewInformation(previewToken)
            .then(function (response) {
            if (response) {
                setAccount(response.account);
                setJob(response.job);
                setJobLineItems(response.jobLineItems);
                setJobLineItemAttachments(response.jobLineItemAttachments);
                setJobLineItemGroups(response.jobLineItemGroups);
                setCustomer(response.customer);
                setAcceptedSignatureImageData(response.acceptedSignatureImageData);
                setJobFinanceInformation(response.jobFinanceInformation);
                setSatisfactionNoteSignatureImageData(response.satisfactionNoteSignatureImageData);
                setJobpayments(response.jobPayments);
            }
            else {
                setError(new Error());
            }
        })
            .catch(function (error) {
            setError(error);
        })
            .finally(function () {
            setIsLoading(false);
            if (shouldAutoPrint) {
                window.print();
            }
        });
    }, [previewToken]);
    var footerSize = useMemo(function () {
        if (!account)
            return 'small';
        var size = 0;
        if (account.address || account.city || account.postcode)
            size++;
        if (account.legalBusinessName !== account.companyName)
            size++;
        if (!!account.vatNumber)
            size++;
        return FOOTER_SIZES[size] || 'large';
    }, [account]);
    if (isLoading || !customer || !job) {
        return React.createElement(LoaderOverlay, null);
    }
    return (React.createElement("div", { className: "job-overview-page", id: "job-overview-page" },
        React.createElement("table", null,
            React.createElement("tbody", null,
                React.createElement("tr", { className: "job-overview-page-inner" },
                    React.createElement("td", null,
                        job && account && (React.createElement(Fragment, null,
                            React.createElement(JobOverviewHeader, { account: account, customer: customer, job: job, jobFinanceInformation: jobFinanceInformation }),
                            React.createElement(JobOverview, { jobLineItems: jobLineItems || [], jobLineItemAttachments: jobLineItemAttachments || [], jobLineItemGroups: jobLineItemGroups || [], customer: customer, job: job, account: account, jobPayments: jobPayments || [], satisfactionNoteSignatureImageData: satisfactionNoteSignatureImageData, jobFinanceInformation: jobFinanceInformation, acceptedSignatureImageData: acceptedSignatureImageData, isEditable: false, onUpdate: function () {
                                    return;
                                } }),
                            React.createElement(JobOverviewBusinessDetails, { account: account, jobFinanceInformation: jobFinanceInformation, job: job }))),
                        error && React.createElement("p", null, "Something went wrong.")))),
            React.createElement("tfoot", null,
                React.createElement("tr", { className: "footer-placeholder-" + footerSize }))),
        job && account && React.createElement(JobOverviewFooter, { account: account })));
};
export default JobOverviewPage;
