import React, { useRef, useState, useCallback } from 'react';
import SignatureCapture from '../signatureCapture/SignatureCapture';
import { Button, ButtonColourVariant, ButtonStyleVariant, } from '@payaca/components/button';
import { MiniLoader, SatisfactionNoteDeclaration } from '@payaca/components';
import agent from '../../agent';
import './SatisfactionNoteSignatureCaptureControl.sass';
var SatisfactionNoteSignatureCaptureControl = function (_a) {
    var agreementDuration = _a.agreementDuration, _b = _a.displaySignHerePrompt, displaySignHerePrompt = _b === void 0 ? false : _b, previewToken = _a.previewToken, companyName = _a.companyName, rejectedAt = _a.rejectedAt, onResponseCaptureComplete = _a.onResponseCaptureComplete, lenderName = _a.lenderName;
    var controlReference = useRef(null);
    var signatureCanvasReference = useRef();
    var _c = useState(false), isSignaturePresent = _c[0], setIsSignaturePresent = _c[1];
    var _d = useState(false), isActionProcessing = _d[0], setIsActionProcessing = _d[1];
    var captureSignature = useCallback(function () {
        var _a, _b;
        if (!isSignaturePresent || ((_a = signatureCanvasReference === null || signatureCanvasReference === void 0 ? void 0 : signatureCanvasReference.current) === null || _a === void 0 ? void 0 : _a.isEmpty())) {
            return;
        }
        else {
            var signatureImage = (_b = signatureCanvasReference === null || signatureCanvasReference === void 0 ? void 0 : signatureCanvasReference.current) === null || _b === void 0 ? void 0 : _b.toDataURL();
            setIsActionProcessing(true);
            agent.Auth.signSatisfactionNote(previewToken, signatureImage).then(function (response) {
                setIsActionProcessing(false);
                if (!(response === null || response === void 0 ? void 0 : response.error)) {
                    onResponseCaptureComplete && onResponseCaptureComplete();
                }
            });
        }
    }, [
        isSignaturePresent,
        signatureCanvasReference,
        previewToken,
        onResponseCaptureComplete,
    ]);
    var rejectSatisfactionNote = useCallback(function () {
        setIsActionProcessing(true);
        agent.Auth.rejectSatisfactionNote(previewToken).then(function (response) {
            setIsActionProcessing(false);
            if (!(response === null || response === void 0 ? void 0 : response.error)) {
                onResponseCaptureComplete && onResponseCaptureComplete();
            }
        });
    }, [previewToken, onResponseCaptureComplete]);
    return (React.createElement("div", { className: "satisfaction-note-signature-capture-control", ref: controlReference },
        React.createElement(SatisfactionNoteDeclaration, { companyName: companyName, agreementDuration: agreementDuration, lenderName: lenderName }),
        !isActionProcessing && (React.createElement(React.Fragment, null,
            React.createElement("p", null,
                "Please sign here to ",
                React.createElement("strong", null, "confirm you are happy"),
                " to activate your loan and that you agree with the above statements"),
            React.createElement(SignatureCapture, { signatureCanvasReference: signatureCanvasReference, setIsSignaturePresent: setIsSignaturePresent, displaySignHerePrompt: displaySignHerePrompt }),
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { isDisabled: !isSignaturePresent || isActionProcessing, onClick: captureSignature, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY }, "Sign to confirm")),
            !rejectedAt && (React.createElement("p", null,
                "If you ",
                React.createElement("strong", null, "disagree with one of the above statements"),
                ' ',
                "please notify us by",
                ' ',
                React.createElement("span", { className: "reject-satisfaction-note-trigger", onClick: rejectSatisfactionNote }, "clicking here"),
                ' ',
                "and one of the team will contact you to attempt to resolve the situation.")),
            rejectedAt && (React.createElement("p", { className: "rejection-description" }, "You have previously let us know that you disagreed with one or more of the above statements. Once the issue is resolved and you agree with the above statements please sign to confirm.")))),
        isActionProcessing && (React.createElement("div", { className: "loader-container" },
            React.createElement(MiniLoader, null)))));
};
export default SatisfactionNoteSignatureCaptureControl;
