import React from 'react';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { Modal } from '@payaca/components/modal';
import './QuoteExpiredModal.sass';
var QuoteExpiredModal = function (_a) {
    var isOpen = _a.isOpen, onAcceptQuote = _a.onAcceptQuote, onClose = _a.onClose;
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, className: "quote-expired-modal", actions: React.createElement(Button, { onClick: onAcceptQuote, styleVariant: ButtonStyleVariant.OUTSIZE }, "Proceed anyway") },
        React.createElement("p", null, "This quote has expired. If you proceed please note that it is not guaranteed that the work can go ahead without prior agreement and additional charges may apply.")));
};
export default QuoteExpiredModal;
