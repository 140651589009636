import React, { Fragment, useMemo } from 'react';
import { getMinimumMonthlyCost, getMinimumDepositPercentageFromJobAndFinancePlan, } from '@payaca/helpers/jobFinanceHelper';
import JobOverviewFinanceBreakdown from '../jobOverviewFinanceBreakdown/JobOverviewFinanceBreakdown';
var JobOverviewFinancePlan = function (_a) {
    var companyName = _a.companyName, financePlan = _a.financePlan, isApplying = _a.isApplying, isSelected = _a.isSelected, job = _a.job, onApply = _a.onApply, onSelectFinancePlan = _a.onSelectFinancePlan, inputValue = _a.inputValue, onInputChange = _a.onInputChange, onSliderChange = _a.onSliderChange, sliderValue = _a.sliderValue, isDisabled = _a.isDisabled;
    var minimumDeposit = useMemo(function () {
        return getMinimumDepositPercentageFromJobAndFinancePlan(job.minimumDepositAmount || null, job.minimumDepositPercentage || null, financePlan.minDeposit, job.total);
    }, [job, financePlan]);
    var minimumMonthlyPayment = useMemo(function () {
        var minimumMonthlyCost = Math.ceil(getMinimumMonthlyCost(job.total, financePlan.maxDeposit, financePlan.minAmount, financePlan.interestRate, financePlan.agreementDuration) / 100);
        // lowest monthly cost from finance plans
        return "\u00A3" + minimumMonthlyCost;
    }, [financePlan, job]);
    var isBuyNowPayLater = useMemo(function () {
        return !!financePlan.deferralPeriod;
    }, [financePlan]);
    var interestRate = useMemo(function () {
        var rate = financePlan.interestRate + "%";
        if (rate.endsWith('.00%')) {
            rate = rate.replace('.00%', '%');
        }
        else if (rate.endsWith('0%')) {
            rate = rate.replace('0%', '%');
        }
        return rate;
    }, [financePlan]);
    return (React.createElement("div", { className: "job-overview-finance-plan" + (isSelected ? ' selected' : '') + (isBuyNowPayLater ? ' buy-now-pay-later' : '') },
        React.createElement("div", { className: "job-overview-finance-plan-summary", onClick: function () { return onSelectFinancePlan(); } },
            React.createElement("div", { className: "agreement-duration-and-minimum-monthly-payment" },
                isBuyNowPayLater ? (React.createElement(Fragment, null,
                    React.createElement("span", { className: "agreement-duration" }, "Buy now pay later"),
                    React.createElement("span", { className: "agreement-duration" },
                        "(",
                        financePlan.deferralPeriod,
                        " months interest free)"))) : (React.createElement("span", { className: "agreement-duration" },
                    financePlan.agreementDuration,
                    " months")),
                React.createElement("span", { className: "minimum-monthly-payment" },
                    "From ",
                    minimumMonthlyPayment,
                    " per month",
                    isBuyNowPayLater ? ' after 12 months' : '')),
            React.createElement("span", { className: "interest-rate-apr" },
                interestRate,
                " APR")),
        React.createElement("div", { className: "job-overview-finance-breakdown-container" }, isSelected && (React.createElement(JobOverviewFinanceBreakdown, { amountTotal: job.total, companyName: companyName, isApplying: isApplying, minFinanceAmount: financePlan.minAmount, maxFinanceAmount: financePlan.maxAmount, maxDepositPercentage: financePlan.maxDeposit, minDepositPercentage: minimumDeposit, onInputChange: onInputChange, onSliderChange: onSliderChange, inputValue: inputValue, sliderValue: sliderValue, financeMonths: financePlan.agreementDuration, isDisabled: isDisabled, interestPercentage: financePlan.interestRate, deferralPeriod: financePlan.deferralPeriod, onApply: onApply })))));
};
export default JobOverviewFinancePlan;
