import React, { useCallback, useMemo, useState } from 'react';
import './DemoQuoteLandingPage.sass';
import { BasicField } from '@payaca/components/basicField';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { RadioGroupField } from '@payaca/components/radioGroupField';
import { actions as accountActions } from '@payaca/store/account';
import { useDispatch } from 'react-redux';
import { ValidatedFieldWrapper } from '@payaca/components/validatedFieldWrapper';
import { ValidatedForm } from '@payaca/components/validatedForm';
import { getEmailFieldValidator, getIsRequiredFieldValidator, getNumericalRangeFieldValidator, getPhoneNumberFieldValidator, } from '@payaca/helpers/fieldValidationHelper';
import { InputStyleVariant } from '@payaca/components/inputWrapper';
import { PhoneNumberField } from '@payaca/components/phoneNumberField';
import { TextareaField } from '@payaca/components';
var INDUSTRY_OPTIONS = [
    { label: 'Plumbing + Heating', value: 'plumbing' },
    { label: 'Electrical', value: 'electrical' },
    { label: 'Building', value: 'building' },
];
var initialFormState = {
    name: '',
    email: '',
    phone: '+44',
    business: '',
    industry: 'plumbing',
    employees: 1,
};
var DemoQuoteLandingPage = function () {
    var dispatch = useDispatch();
    var _a = useState(false), quoteSent = _a[0], setQuoteSent = _a[1];
    var fieldValidators = useMemo(function () {
        var isRequired = getIsRequiredFieldValidator();
        return {
            name: [isRequired],
            email: [isRequired, getEmailFieldValidator()],
            phone: [isRequired, getPhoneNumberFieldValidator()],
            business: [isRequired],
            employees: [getNumericalRangeFieldValidator(0, 10000)],
        };
    }, []);
    var userId = useMemo(function () {
        var queryParams = new URLSearchParams(window.location.search);
        var id = queryParams.get('userId');
        if (id) {
            return +id;
        }
        return undefined;
    }, [window.location]);
    var payacasource = useMemo(function () {
        var queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('payacasource') || undefined;
    }, [window.location]);
    var dealstage = useMemo(function () {
        var queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('dealstage') || undefined;
    }, [window.location]);
    var onSendDemoQuote = function (_a) {
        var email = _a.email, name = _a.name, phone = _a.phone, business = _a.business, industry = _a.industry, employees = _a.employees, notes = _a.notes;
        dispatch(accountActions.requestSendDemoQuote(email, name, phone, business, industry, employees, notes, process.env.REACT_APP_DEMO_QUOTE_TOKEN, userId, payacasource, dealstage));
        setQuoteSent(true);
    };
    var renderFormContents = useCallback(function (isValid, formState, validationState, touchedState, onFieldChange, onFieldTouch) {
        return (React.createElement("form", null,
            React.createElement("div", { className: 'form-item' },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.name, isTouched: touchedState.name || false },
                    React.createElement(BasicField, { name: 'name', label: 'Name:', styleVariant: InputStyleVariant.OUTSIZE, value: formState.name, onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: 'form-item' },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.email, isTouched: touchedState.email || false },
                    React.createElement(BasicField, { name: 'email', label: 'E-mail:', styleVariant: InputStyleVariant.OUTSIZE, value: formState.email, onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: 'form-item' },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.phone, isTouched: touchedState.phone || false },
                    React.createElement(PhoneNumberField, { name: 'phone', label: 'Phone:', styleVariant: InputStyleVariant.OUTSIZE, value: formState.phone, onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: 'form-item' },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.business, isTouched: touchedState.business || false },
                    React.createElement(BasicField, { name: 'business', label: 'Business name:', styleVariant: InputStyleVariant.OUTSIZE, value: formState.business, onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: 'form-item' },
                React.createElement(RadioGroupField, { name: 'industry', label: 'Industry', options: INDUSTRY_OPTIONS, value: formState.industry, onChange: onFieldChange, onTouch: onFieldTouch })),
            React.createElement("div", { className: 'form-item' },
                React.createElement(ValidatedFieldWrapper, { validationResult: validationState.employees, isTouched: touchedState.employees || false },
                    React.createElement(BasicField, { name: 'employees', label: 'Employees:', styleVariant: InputStyleVariant.OUTSIZE, value: formState.employees, onChange: onFieldChange, onTouch: onFieldTouch }))),
            React.createElement("div", { className: 'form-item' },
                React.createElement(TextareaField, { name: 'notes', label: 'What interests you most about Payaca?', styleVariant: InputStyleVariant.OUTSIZE, value: formState.notes, onChange: onFieldChange, onTouch: onFieldTouch })),
            React.createElement("div", { className: 'form-item generate-quote-cta' },
                React.createElement(Button, { styleVariant: ButtonStyleVariant.OUTSIZE, onClick: function () { return onSendDemoQuote(formState); }, isDisabled: !isValid }, "Generate a demo quote"))));
    }, []);
    return (React.createElement("div", { className: 'demo-quote-landing-page-wrapper' },
        React.createElement("div", { className: 'demo-quote-landing-page-content' },
            React.createElement("div", { className: 'demo-quote-landing-page-header' },
                React.createElement("img", { src: 'https://storage.googleapis.com/payaca-prod-assets/logo-large.png' }),
                React.createElement("img", { src: 'https://storage.googleapis.com/payaca-prod-assets/payaca-grow-like-a-pro.png' })),
            !quoteSent ? (React.createElement(React.Fragment, null,
                React.createElement("p", null, "Check out what your customers will see:"),
                React.createElement(ValidatedForm, { initialFormState: initialFormState, renderFormContents: renderFormContents, fieldValidators: fieldValidators }))) : (React.createElement(React.Fragment, null,
                React.createElement("p", null, "Thanks!"),
                React.createElement("p", null, "Check your inbox."),
                React.createElement("br", null),
                React.createElement("p", null,
                    React.createElement("a", { href: '/' + window.location.search }, "Send another")))))));
};
export default DemoQuoteLandingPage;
