var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeEvery, call, put, race, delay, takeLatest, } from 'redux-saga/effects';
import { AccountActionTypes } from './accountTypes';
import { getAccountAccessInformationFailure, getAccountAccessInformationSuccess, requestGetAccountAccessInformation, clearAccountAccessInformation, bookDemoFailure, bookDemoSuccess, getEmailTemplatesFailure, getEmailTemplatesSuccess, storeEmailTemplates, sendDemoQuoteFailure, sendDemoQuoteSuccess, } from './accountActions';
import { Req } from '@payaca/helpers/storeHelper';
import { refreshAuthToken } from '../auth/authSaga';
var accountSagaCreator = function (_a) {
    var apiBaseurl = _a.apiBaseurl, getAuthHeader = _a.getAuthHeader, isNativeApp = _a.isNativeApp;
    var req = Req(apiBaseurl, getAuthHeader, isNativeApp);
    function handleGetAccountAccessInformation(action) {
        var _a, response, timeout, error_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(getAccountAccessInformation),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    return [4 /*yield*/, put(getAccountAccessInformationFailure(new Error('Get account access information request timed out.')))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(getAccountAccessInformationSuccess(response))];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_1 = _b.sent();
                    return [4 /*yield*/, put(getAccountAccessInformationFailure(error_1))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    var getAccountAccessInformation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/accounts/access_information"];
                    _c = {
                        method: 'GET'
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c)])).then(function (response) {
                        if (response.ok) {
                            return response.json();
                        }
                        else {
                            throw new Error("GetAccountAccessInformation failed: " + response.status + " " + response.statusText);
                        }
                    })];
            }
        });
    }); };
    function handleRequestBookDemo(action) {
        var _a, response, timeout, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(bookDemo, action.payload.bookDemoFor, action.payload.isImmediateCallback),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    return [4 /*yield*/, put(bookDemoFailure(new Error('Book demo request timed out.')))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(bookDemoSuccess())];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_2 = _b.sent();
                    return [4 /*yield*/, put(bookDemoFailure(error_2))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    var bookDemo = function (bookDemoFor, isImmediateCallback) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    _a = fetch;
                    _b = [apiBaseurl + "/accounts/book_demo"];
                    _c = {
                        method: 'POST',
                        body: JSON.stringify({
                            bookDemoFor: bookDemoFor,
                            isImmediateCallback: isImmediateCallback,
                        })
                    };
                    _d = {};
                    return [4 /*yield*/, getAuthHeader()];
                case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([(_c.headers = (_d.Authorization = _e.sent(),
                            _d['Content-Type'] = 'application/json',
                            _d['X-Simple-Job'] = 'true',
                            _d),
                            _c)])).then(function (response) {
                        if (response.ok) {
                            return;
                        }
                        else {
                            throw new Error("BookDemo failed failed: " + response.status + " " + response.statusText);
                        }
                    })];
            }
        });
    }); };
    function handleRequestSendDemoQuote(action) {
        var _a, response, timeout, error_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 8, , 10]);
                    return [4 /*yield*/, race({
                            response: call(sendDemoQuote, action.payload.sendToEmailAddress, action.payload.name, action.payload.phone, action.payload.businessName, action.payload.industry, action.payload.employees, action.payload.notes, action.payload.token, action.payload.ownerUserId, action.payload.source, action.payload.dealStage),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    return [4 /*yield*/, put(sendDemoQuoteFailure(new Error('Send demo quote request timed out.')))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 5: return [4 /*yield*/, put(sendDemoQuoteSuccess())];
                case 6:
                    _b.sent();
                    _b.label = 7;
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_3 = _b.sent();
                    return [4 /*yield*/, put(sendDemoQuoteFailure(error_3))];
                case 9:
                    _b.sent();
                    return [3 /*break*/, 10];
                case 10: return [2 /*return*/];
            }
        });
    }
    var sendDemoQuote = function (sendToEmailAddress, name, phone, businessName, industry, employees, notes, token, ownerUserId, source, dealStage) { return __awaiter(void 0, void 0, void 0, function () {
        var authHeader, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAuthHeader()];
                case 1:
                    authHeader = _a.sent();
                    return [4 /*yield*/, fetch(apiBaseurl + "/accounts/send_unauthenticated_demo_quote" + (token ? "?token=" + token : ''), {
                            method: 'POST',
                            body: JSON.stringify({
                                sendToEmailAddress: sendToEmailAddress,
                                name: name,
                                phone: phone,
                                businessName: businessName,
                                industry: industry,
                                employees: employees,
                                notes: notes,
                                ownerUserId: ownerUserId,
                                source: source,
                                dealStage: dealStage,
                            }),
                            headers: {
                                Authorization: authHeader,
                                'Content-Type': 'application/json',
                                'X-Simple-Job': 'true',
                            },
                        })];
                case 2:
                    response = _a.sent();
                    if (response.ok) {
                        return [2 /*return*/];
                    }
                    else {
                        throw new Error("Send demo quote failed: " + response.status + " " + response.statusText);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    function handleGetEmailTemplates(action) {
        var _a, response, timeout, errorMessage, error_4;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 9, , 11]);
                    return [4 /*yield*/, race({
                            response: call(getEmailTemplates, action.payload.accountId, action.payload.includeDefaults),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 5];
                    errorMessage = 'Get email templates request timed out.';
                    return [4 /*yield*/, put(getEmailTemplatesFailure(new Error(errorMessage), errorMessage))];
                case 4:
                    _b.sent();
                    return [3 /*break*/, 8];
                case 5: return [4 /*yield*/, put(storeEmailTemplates(response))];
                case 6:
                    _b.sent();
                    return [4 /*yield*/, put(getEmailTemplatesSuccess(response))];
                case 7:
                    _b.sent();
                    _b.label = 8;
                case 8: return [3 /*break*/, 11];
                case 9:
                    error_4 = _b.sent();
                    return [4 /*yield*/, put(getEmailTemplatesFailure(error_4, error_4.message))];
                case 10:
                    _b.sent();
                    return [3 /*break*/, 11];
                case 11: return [2 /*return*/];
            }
        });
    }
    var getEmailTemplates = function (accountId, includeDefaults) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, req.get("/accounts/" + accountId + "/email_templates?includeDefaults=" + includeDefaults)];
                case 1:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _a.sent()];
                case 3: throw new Error("GetEmailTemplates failed: " + response.status + " " + response.statusText);
            }
        });
    }); };
    function handleUpdateEmailTemplates(action) {
        var _a, response, timeout, error_5;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, call(refreshAuthToken)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 7, , 8]);
                    return [4 /*yield*/, race({
                            response: call(updateEmailTemplates, action.payload.accountId, action.payload.emailTemplates),
                            timeout: delay(15000),
                        })];
                case 3:
                    _a = _b.sent(), response = _a.response, timeout = _a.timeout;
                    if (!timeout) return [3 /*break*/, 4];
                    throw 'Updating email templates timed out';
                case 4: return [4 /*yield*/, put(storeEmailTemplates(response))];
                case 5:
                    _b.sent();
                    action.payload.callback(null);
                    _b.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    error_5 = _b.sent();
                    action.payload.callback(error_5);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }
    var updateEmailTemplates = function (accountId, emailTemplates) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, req.put("/accounts/" + accountId + "/email_templates", emailTemplates)];
                case 1:
                    response = _a.sent();
                    if (!response.ok) return [3 /*break*/, 3];
                    return [4 /*yield*/, response.json()];
                case 2: return [2 /*return*/, _a.sent()];
                case 3: throw new Error("GetEmailTemplates failed: " + response.status + " " + response.statusText);
            }
        });
    }); };
    function handleAppLoaded() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetAccountAccessInformation())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleAppSaveToken() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetAccountAccessInformation())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleAppLogout() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(clearAccountAccessInformation())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleLoginSuccess() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetAccountAccessInformation())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleBookDemoSuccess() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetAccountAccessInformation())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleCreateSubscriptionSuccess() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetAccountAccessInformation())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    function handleUpdateSubscriptionSuccess() {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, put(requestGetAccountAccessInformation())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }
    return function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, takeLatest(AccountActionTypes.REQUEST_GET_ACCOUNT_ACCESS_INFORMATION, handleGetAccountAccessInformation)];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AccountActionTypes.REQUEST_BOOK_DEMO, handleRequestBookDemo)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AccountActionTypes.REQUEST_SEND_DEMO_QUOTE, handleRequestSendDemoQuote)];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('app.loaded', handleAppLoaded)];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('auth.logout', handleAppLogout)];
                case 5:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('auth.loginSuccess', handleLoginSuccess)];
                case 6:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('app.saveToken', handleAppSaveToken)];
                case 7:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('subscription.createSubscriptionSuccess', handleCreateSubscriptionSuccess)];
                case 8:
                    _a.sent();
                    return [4 /*yield*/, takeEvery('subscription.updateSubscriptionSuccess', handleUpdateSubscriptionSuccess)];
                case 9:
                    _a.sent();
                    return [4 /*yield*/, takeEvery(AccountActionTypes.BOOK_DEMO_SUCCESS, handleBookDemoSuccess)];
                case 10:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AccountActionTypes.REQUEST_GET_EMAIL_TEMPLATES, handleGetEmailTemplates)];
                case 11:
                    _a.sent();
                    return [4 /*yield*/, takeLatest(AccountActionTypes.REQUEST_UPDATE_EMAIL_TEMPLATES, handleUpdateEmailTemplates)];
                case 12:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    };
};
export default accountSagaCreator;
