import React, { useCallback, useMemo, useState, } from 'react';
// @ts-ignore
import { Lightbox } from 'react-modal-image';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import RadioButton from '../radioButton/RadioButton';
import Checkbox from '../checkbox/Checkbox';
var vatFormatter = new Intl.NumberFormat('en-GB');
var JobOverviewItem = function (_a) {
    var hideItemPrices = _a.hideItemPrices, hideVat = _a.hideVat, item = _a.item, isEditable = _a.isEditable, _b = _a.isProposition, isProposition = _b === void 0 ? false : _b, attachments = _a.attachments, onItemClick = _a.onItemClick, selectedMultipleChoiceItem = _a.selectedMultipleChoiceItem;
    var _c = useState(null), enlargeImage = _c[0], setEnlargeImage = _c[1];
    var totalExcVat = useMemo(function () {
        return Number(item.quantity) * item.priceIncMarkup;
    }, [item]);
    var totalIncVat = useMemo(function () {
        if (item.vatIncluded && !item.isReverseChargeVat) {
            return totalExcVat + (totalExcVat * item.vatAmount) / 100;
        }
        else {
            return totalExcVat;
        }
    }, [totalExcVat, item]);
    var cisApplies = useMemo(function () {
        return (item.cisDeductionRate !== null && item.cisDeductionRate !== undefined);
    }, [item]);
    var cisTotal = useMemo(function () {
        if (!cisApplies)
            return 0;
        return totalExcVat * ((item.cisDeductionRate || 0) / 100);
    }, [cisApplies, totalExcVat, item]);
    var openImage = useCallback(function (itemAttachment) {
        setEnlargeImage({
            url: itemAttachment.originalAttachmentUrl || itemAttachment.attachmentUrl,
            fileName: itemAttachment.fileName,
        });
    }, []);
    var costDifference = useMemo(function () {
        // must be quote and optional/multiple choice selected item
        if (isProposition) {
            if (item.isOptional && !item.isSelected) {
                return "" + currencyPrice(totalIncVat);
            }
            else if (item.isMultipleChoice &&
                !item.isSelected &&
                selectedMultipleChoiceItem) {
                // get cost of selected multiple choice item
                var selectedItemTotalExcVat = Number(selectedMultipleChoiceItem.quantity) *
                    selectedMultipleChoiceItem.priceIncMarkup;
                var selectedItemTotalIncVat = void 0;
                if (selectedMultipleChoiceItem.vatIncluded &&
                    !selectedMultipleChoiceItem.isReverseChargeVat) {
                    selectedItemTotalIncVat =
                        selectedItemTotalExcVat +
                            (selectedItemTotalExcVat * selectedMultipleChoiceItem.vatAmount) /
                                100;
                }
                else {
                    selectedItemTotalIncVat = selectedItemTotalExcVat;
                }
                // difference between item selected multiple choice item
                var difference = totalIncVat - selectedItemTotalIncVat;
                if (difference > 0) {
                    return currencyPrice(Math.abs(difference));
                }
            }
        }
        return null;
    }, [isProposition, item, selectedMultipleChoiceItem, totalIncVat]);
    return (React.createElement("div", { className: "job-overview-item" + ((item.isOptional || item.isMultipleChoice) && !item.isSelected
            ? ' unselected'
            : '') + (isEditable ? '' : ' disabled') + (!(item.isOptional || item.isMultipleChoice) ? ' required' : ''), onClick: function () { return (isEditable ? onItemClick(item) : undefined); } },
        React.createElement("div", { className: "job-overview-item-inner" },
            costDifference && (React.createElement("div", { className: "tooltip-container" },
                React.createElement("span", { className: "tooltip-text" },
                    "Select this item for only ",
                    costDifference,
                    " more"))),
            React.createElement("div", { className: "radio-checkbox-description-container" },
                item.isOptional && isProposition && (React.createElement("div", { className: "radio-checkbox-container" },
                    React.createElement(Checkbox, { isChecked: !!item.isSelected, onChange: function () { return undefined; }, isDisabled: !isEditable }))),
                item.isMultipleChoice && isProposition && (React.createElement("div", { className: "radio-checkbox-container" },
                    React.createElement(RadioButton, { isSelected: !!item.isSelected, onClick: function () { return undefined; }, isDisabled: !isEditable }))),
                !!(attachments === null || attachments === void 0 ? void 0 : attachments.length) && (React.createElement("div", { className: "images-wrapper" },
                    attachments.map(function (itemAttachment, i) { return (React.createElement("img", { key: itemAttachment.fileName + "-" + i, src: itemAttachment.attachmentUrl, onClick: function (e) {
                            e.stopPropagation(); //dont select item too
                            openImage(itemAttachment);
                        }, className: "image", alt: "Attachment for " + item.description })); }),
                    enlargeImage && (React.createElement(Lightbox, { medium: enlargeImage.url, onClose: function () { return setEnlargeImage(null); }, alt: enlargeImage.fileName })))),
                React.createElement("div", { className: "description-details-wrapper" },
                    React.createElement("span", { className: "description" }, item.description),
                    React.createElement("div", { className: "item-details" },
                        React.createElement("div", null,
                            !hideItemPrices && (React.createElement("span", { className: "unit-price" },
                                React.createElement("span", { className: "label" }, "Unit price"),
                                React.createElement("span", { className: "value" }, currencyPrice(item.priceIncMarkup)))),
                            React.createElement("span", { className: "quantity" },
                                React.createElement("span", { className: "label" }, "Qty"),
                                React.createElement("span", { className: "value" }, item.quantity)),
                            !hideItemPrices && !hideVat && (React.createElement("div", { className: "vat" },
                                React.createElement("span", { className: "label" }, "VAT"),
                                React.createElement("span", { className: "value" }, item.vatIncluded
                                    ? vatFormatter.format(item.vatAmount) + "%"
                                    : 'No VAT'))),
                            !hideItemPrices && (React.createElement("div", { className: "total" },
                                React.createElement("span", { className: "label" }, "Total"),
                                React.createElement("div", { className: "value" },
                                    React.createElement("span", null, currencyPrice(totalExcVat)),
                                    item.vatIncluded && (React.createElement("span", { className: "including-vat" }, item.isReverseChargeVat
                                        ? 'Reverse charge VAT'
                                        : "inc. VAT " + currencyPrice(totalIncVat))))))),
                        cisApplies && (React.createElement("div", { className: "cis" },
                            React.createElement("span", { className: "description flex" },
                                "CIS suffered at ",
                                item.cisDeductionRate,
                                "% deduction rate"),
                            !hideItemPrices && (React.createElement("span", { className: "value" },
                                React.createElement("span", null,
                                    " ",
                                    currencyPrice(cisTotal * -1))))))))))));
};
export default JobOverviewItem;
