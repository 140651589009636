import React from 'react';
import { Modal } from '@payaca/components/modal';
import './JobSignatureCaptureErrorModal.sass';
var JobSignatureCaptureErrorModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose;
    return (React.createElement("div", { className: "job-signature-capture-error-modal-container" },
        React.createElement(Modal, { isOpen: isOpen, onClose: onClose },
            React.createElement("div", { className: "job-signature-capture-error-container" },
                React.createElement("h3", null, "Error accepting quote"),
                React.createElement("p", null, "Sorry, there was an error recording your signature. Please try again.")))));
};
export default JobSignatureCaptureErrorModal;
