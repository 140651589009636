import React, { Fragment } from 'react';
import JobOverviewItemGroup from './JobOverviewItemGroup';
var JobOverviewItemGroups = function (_a) {
    var hideItemPrices = _a.hideItemPrices, _b = _a.hideVat, hideVat = _b === void 0 ? false : _b, _c = _a.isEditable, isEditable = _c === void 0 ? false : _c, isProposition = _a.isProposition, jobLineItemGroups = _a.jobLineItemGroups, jobLineItems = _a.jobLineItems, jobLineItemAttachments = _a.jobLineItemAttachments, onUpdate = _a.onUpdate;
    return (React.createElement("div", { className: "job-overview-item-groups" },
        React.createElement("div", { className: "item-group-print-headers" },
            React.createElement("span", { className: "description" }, "Description"),
            React.createElement("span", { className: "unit-price" }, "Unit price"),
            React.createElement("span", { className: "quantity" }, "Quantity"),
            React.createElement("span", { className: "vat" }, "VAT"),
            React.createElement("span", { className: "total" }, "Total")),
        React.createElement("div", { className: "" + (jobLineItemGroups.length > 1 ? '' : 'single-group') }, jobLineItemGroups.length > 0 &&
            jobLineItemGroups.map(function (jobLineItemGroup, i) {
                var jobLineItemsForGroup = jobLineItems.filter(function (jobLineItem) {
                    return jobLineItemGroup.jobLineItemIds.includes(jobLineItem.id);
                });
                var jobLineItemAttachmentIdsForGroup = jobLineItems.flatMap(function (jobLineItem) { return jobLineItem.jobLineItemAttachmentIds; });
                var jobLineItemAttachmentsForGroup = jobLineItemAttachments.filter(function (jobLineItemAttachment) {
                    return jobLineItemAttachmentIdsForGroup.includes(jobLineItemAttachment.id);
                });
                return (React.createElement(Fragment, { key: i },
                    React.createElement(JobOverviewItemGroup, { key: i, jobLineItemGroup: jobLineItemGroup, jobLineItems: jobLineItemsForGroup, jobLineItemAttachments: jobLineItemAttachmentsForGroup, onUpdate: onUpdate, isEditable: isEditable, isProposition: isProposition, hideItemPrices: hideItemPrices, hideVat: hideVat })));
            }))));
};
export default JobOverviewItemGroups;
