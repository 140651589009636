import React from 'react';
import JobOverviewFinancePlan from './JobOverviewFinancePlan';
import './JobOverviewFinancePlans.sass';
var JobOverviewFinancePlans = function (_a) {
    var companyName = _a.companyName, financePlans = _a.financePlans, inputValue = _a.inputValue, isApplying = _a.isApplying, isDisabled = _a.isDisabled, job = _a.job, onApply = _a.onApply, onInputChange = _a.onInputChange, onSelectedFinancePlanChange = _a.onSelectedFinancePlanChange, onSliderChange = _a.onSliderChange, selectedFinancePlan = _a.selectedFinancePlan, sliderValue = _a.sliderValue;
    return (React.createElement("div", { className: "job-overview-finance-plans" + (selectedFinancePlan ? ' selected-open' : '') }, job &&
        financePlans.map(function (financePlan, i) { return (React.createElement(JobOverviewFinancePlan, { key: i, financePlan: financePlan, isSelected: !!(selectedFinancePlan === financePlan), job: job, onSelectFinancePlan: function () { return onSelectedFinancePlanChange(financePlan); }, companyName: companyName, onInputChange: onInputChange, onSliderChange: onSliderChange, inputValue: inputValue, isDisabled: isDisabled, sliderValue: sliderValue, onApply: function () {
                return !!((selectedFinancePlan === null || selectedFinancePlan === void 0 ? void 0 : selectedFinancePlan.id) === financePlan.id)
                    ? onApply(financePlan)
                    : null;
            }, isApplying: isApplying })); })));
};
export default JobOverviewFinancePlans;
