import React, { useCallback, useMemo } from 'react';
import JobOverviewItemsSection from './JobOverviewItemsSection';
var JobOverviewItemGroup = function (_a) {
    var hideItemPrices = _a.hideItemPrices, hideVat = _a.hideVat, isEditable = _a.isEditable, _b = _a.isProposition, isProposition = _b === void 0 ? false : _b, jobLineItemGroup = _a.jobLineItemGroup, jobLineItems = _a.jobLineItems, jobLineItemAttachments = _a.jobLineItemAttachments, onUpdate = _a.onUpdate;
    var onOptionalClick = useCallback(function (item) {
        onUpdate({
            jobLineItems: [
                {
                    jobLineItemId: item.id,
                    isSelected: !item.isSelected,
                },
            ],
        });
    }, [onUpdate]);
    var onMultipleChoiceClick = useCallback(function (selectedItem) {
        //  get all multple choice items in group and update isSelected
        var itemsToUpdate = jobLineItems
            .filter(function (i) { return i.isMultipleChoice; })
            .map(function (jobLineItem) {
            return {
                jobLineItemId: jobLineItem.id,
                isSelected: jobLineItem.id === selectedItem.id,
            };
        });
        onUpdate({ jobLineItems: itemsToUpdate });
    }, [jobLineItems, onUpdate]);
    var onItemClick = useCallback(function (item) {
        if (item.isOptional) {
            onOptionalClick(item);
        }
        else if (item.isMultipleChoice) {
            onMultipleChoiceClick(item);
        }
    }, [onOptionalClick, onMultipleChoiceClick]);
    var requiredItems = useMemo(function () {
        return jobLineItems
            ? jobLineItems.filter(function (jobLineItem) {
                return !jobLineItem.isOptional && !jobLineItem.isMultipleChoice;
            })
            : [];
    }, [jobLineItems]);
    var optionalItems = useMemo(function () {
        return jobLineItems
            ? jobLineItems.filter(function (jobLineItem) { return jobLineItem.isOptional; })
            : [];
    }, [jobLineItems]);
    var multipleChoiceItems = useMemo(function () {
        return jobLineItems
            ? jobLineItems.filter(function (jobLineItem) { return jobLineItem.isMultipleChoice; })
            : [];
    }, [jobLineItems]);
    var invoiceSelectedItems = useMemo(function () {
        return jobLineItems
            ? jobLineItems.filter(function (jobLineItem) {
                return (!jobLineItem.isOptional && !jobLineItem.isMultipleChoice) ||
                    jobLineItem.isSelected;
            })
            : [];
    }, [jobLineItems]);
    var showDescription = useMemo(function () { return isProposition && jobLineItemGroup.description; }, [isProposition, jobLineItemGroup.description]);
    return (React.createElement("div", { className: "job-overview-item-group-section" + (isProposition ? ' proposition' : '') },
        isProposition && (React.createElement("div", { className: "job-overview-items-group-description-container" }, showDescription && (React.createElement("h4", { className: "job-overview-items-group-description" }, jobLineItemGroup.description)))),
        isProposition ? (React.createElement("div", { className: "job-overview-items-wrapper" },
            requiredItems.length > 0 && (React.createElement(JobOverviewItemsSection, { jobLineItems: requiredItems, jobLineItemAttachments: jobLineItemAttachments, onItemClick: onItemClick, isEditable: isEditable, isProposition: isProposition, hideItemPrices: hideItemPrices, hideVat: hideVat })),
            multipleChoiceItems.length > 0 && (React.createElement(JobOverviewItemsSection, { jobLineItems: multipleChoiceItems, jobLineItemAttachments: jobLineItemAttachments, helperTitle: "Select one of the following:", onItemClick: onItemClick, isEditable: isEditable, isProposition: isProposition, hideItemPrices: hideItemPrices, hideVat: hideVat })),
            optionalItems.length > 0 && (React.createElement(JobOverviewItemsSection, { jobLineItems: optionalItems, jobLineItemAttachments: jobLineItemAttachments, helperTitle: "Optional extras:", onItemClick: onItemClick, isEditable: isEditable, isProposition: isProposition, hideItemPrices: hideItemPrices, hideVat: hideVat })))) : (React.createElement(JobOverviewItemsSection, { jobLineItems: invoiceSelectedItems, jobLineItemAttachments: jobLineItemAttachments, isEditable: isEditable, isProposition: isProposition, onItemClick: onItemClick, hideItemPrices: hideItemPrices, hideVat: hideVat }))));
};
export default JobOverviewItemGroup;
