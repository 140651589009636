import React from 'react';
import { Button } from '@payaca/components/button';
import { PaymentMethodIcons } from '@payaca/components';
import './JobOverviewCta.sass';
var JobOverviewCta = function (_a) {
    var children = _a.children, _b = _a.isDisabled, isDisabled = _b === void 0 ? false : _b, _c = _a.isProcessing, isProcessing = _c === void 0 ? false : _c, onClick = _a.onClick, _d = _a.showPaymentMethodIcons, showPaymentMethodIcons = _d === void 0 ? true : _d;
    return (React.createElement("div", { className: "job-overview-cta" },
        React.createElement(Button, { onClick: onClick, isDisabled: isDisabled, isProcessing: isProcessing }, children),
        showPaymentMethodIcons && React.createElement(PaymentMethodIcons, null)));
};
export default JobOverviewCta;
