import React from "react";
import "./FinanceOptionsOverlay.css";
import FinanceOptionsOverlayActionBar from "./FinanceOptionsOverlayActionBar";
import FinanceOptionsExplainer from "./FinanceOptionsExplainer";
var FinanceOptionsOverlay = function (_a) {
    var _b = _a.isActive, isActive = _b === void 0 ? false : _b, onClose = _a.onClose, hasSimpleFinanceOption = _a.hasSimpleFinanceOption, hasSelinaFinanceOption = _a.hasSelinaFinanceOption, goToSimpleFinanceApply = _a.goToSimpleFinanceApply, goToSelinaFinanceApply = _a.goToSelinaFinanceApply;
    return (React.createElement("div", { className: "finance-options-overlay" + (isActive ? " active" : "") },
        React.createElement(FinanceOptionsOverlayActionBar, { onClose: onClose }),
        React.createElement("div", { className: "finance-options-explainer-container" },
            React.createElement(FinanceOptionsExplainer, { overlayIsActive: isActive, hasSimpleFinanceOption: hasSimpleFinanceOption, hasSelinaFinanceOption: hasSelinaFinanceOption, goToSimpleFinanceApply: goToSimpleFinanceApply, goToSelinaFinanceApply: goToSelinaFinanceApply }))));
};
export default FinanceOptionsOverlay;
