import React, { useMemo } from 'react';
import { Modal } from '@payaca/components/modal';
import { Button, ButtonColourVariant, ButtonStyleVariant, } from '@payaca/components/button';
import './JobSignatureCaptureFeedbackModal.sass';
var JobSignatureCaptureFeedbackModal = function (_a) {
    var isOpen = _a.isOpen, _b = _a.isPaymentRequired, isPaymentRequired = _b === void 0 ? false : _b, amountDue = _a.amountDue, onRequestPayNow = _a.onRequestPayNow, onClose = _a.onClose;
    var showPayNowPrompt = useMemo(function () {
        return isPaymentRequired && !!amountDue;
    }, [isPaymentRequired, amountDue]);
    return (React.createElement(Modal, { className: "job-signature-capture-feedback-modal", isOpen: isOpen, onClose: onClose, size: "xs", title: "Thank you for accepting!", actions: showPayNowPrompt ? (React.createElement(Button, { onClick: onRequestPayNow, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.SECONDARY }, "Pay now")) : undefined }, showPayNowPrompt && React.createElement("p", null, "Please click below to continue to payment")));
};
export default JobSignatureCaptureFeedbackModal;
