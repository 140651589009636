import React, { useCallback } from 'react';
import FileSaver from 'file-saver';
import { Download } from 'react-iconly';
import { Button } from '@payaca/components/button';
import { VisitRelatedJobLink } from '../../components';
import './JobViewerHeader.sass';
var JobViewerHeader = function (_a) {
    var fileName = _a.fileName, _b = _a.hideRelatedJobInformation, hideRelatedJobInformation = _b === void 0 ? true : _b, _c = _a.isCollapsed, isCollapsed = _c === void 0 ? false : _c, pdfData = _a.pdfData, jobOverviewCta = _a.jobOverviewCta, jobTitle = _a.jobTitle, relatedJobInformation = _a.relatedJobInformation;
    var handleDownloadPdfData = useCallback(function () {
        // convert pdfData to blob
        var binary = atob(pdfData.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        // create the blob object with content-type "application/pdf"
        var blob = new Blob([view], { type: 'application/pdf' });
        // use filesaver to download the blob
        FileSaver.saveAs(blob, fileName);
    }, [pdfData, fileName]);
    return (React.createElement("div", { className: "job-viewer-header" + (isCollapsed ? ' collapsed' : '') },
        jobTitle && React.createElement("h3", { className: "job-title" }, jobTitle),
        React.createElement("div", null, relatedJobInformation && !hideRelatedJobInformation && (React.createElement(VisitRelatedJobLink, { jobType: relatedJobInformation.jobType, previewUrl: relatedJobInformation.previewUrl }))),
        React.createElement("div", { className: "job-viewer-buttons" },
            React.createElement("a", { onClick: handleDownloadPdfData, className: "job-viewer-button pdf-download" },
                React.createElement(Button, null,
                    React.createElement(Download, { set: "light" })),
                React.createElement("span", { className: "download-text" }, "Download as PDF")),
            jobOverviewCta)));
};
export default JobViewerHeader;
