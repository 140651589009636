var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState, useEffect, useMemo, useCallback, useRef, } from 'react';
import scrollToWithAnimation from 'scrollto-with-animation';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { PaymentMethodType } from '@payaca/types/jobPaymentTypes';
import { ContentPanel, FinanceOptionsOverlay, JobAttachments, JobOverviewBusinessDetails, JobOverviewFinancePlans, JobOverviewFooter, JobOverviewTimeline, LoaderOverlay, } from '@payaca/components';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import { Badge } from '@payaca/components/badge';
import { Modal } from '@payaca/components/modal';
import { JobOverviewHeader } from '@payaca/components/jobOverviewHeader';
import { JobOverview } from '@payaca/components/jobOverview';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { getJobType, isDepositPaymentRequired, isDepositAttemptedPaid, isDepositConfirmedPaid, isInvoiceAttemptedPaid, isInvoiceConfirmedPaid, isInvoicePaymentRequired, isSatisfactionNoteSignatureRequired, shouldShowFinanceOptionsToCustomer, } from '@payaca/helpers/jobHelperV2';
import { getAttemptedDepositPaymentValueFromJobPayments, getTotalAttemptedPaymentValueFromJobPayments, } from '@payaca/helpers/jobPaymentHelper';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import { hasAcceptedFinanceApplication, hasRejectedFinanceApplication, hasSubmittedFinanceApplication, isCreditRequestValid, } from '@payaca/helpers/jobFinanceApplicationHelper';
import { getMinimumMonthlyCost } from '@payaca/helpers/jobFinanceHelper';
import { useSmallViewListener } from '../../utils/customHooks';
import agent from '../../agent';
import { DeclineJobModal, JobViewerHeader, JobOverviewCta, JobPaymentModal, JobSignatureCaptureControl, JobSignatureCaptureFeedbackModal, JobSignatureCaptureErrorModal, SatisfactionNoteSignatureCaptureControl, } from '../../components';
import './Job.sass';
var FINANCE_URL = process.env.REACT_APP_FINANCE_URL;
var getIsScrolledToTop = function (scrollOffset) {
    if (scrollOffset >= -10) {
        return true;
    }
    return false;
};
var getIsScrolledToBottom = function (scrollOffset) {
    if (document.documentElement.offsetHeight === 0)
        return false;
    if (scrollOffset <=
        document.documentElement.clientHeight -
            document.documentElement.offsetHeight +
            10) {
        return true;
    }
    return false;
};
function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
}
var Job = function (_a) {
    var isProposition = _a.isProposition;
    var financeBreakdownRef = useRef(null);
    var isSmallView = useSmallViewListener(financeBreakdownRef, 350)[0];
    var location = window.location;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(), job = _c[0], setJob = _c[1];
    var _d = useState(), account = _d[0], setAccount = _d[1];
    var _e = useState(), accountAccessInformation = _e[0], setAccountAccessInformation = _e[1];
    var _f = useState(false), isFinanceOptionsOverlayActive = _f[0], setIsFinanceOptionsOverlayActive = _f[1];
    var _g = useState(false), isCustomerPreview = _g[0], setIsCustomerPreview = _g[1];
    var _h = useState(), customer = _h[0], setCustomer = _h[1];
    var _j = useState(''), pdfData = _j[0], setPdfData = _j[1];
    var _k = useState(), terms = _k[0], setTerms = _k[1];
    var _l = useState(), businessAttachments = _l[0], setBusinessAttachments = _l[1];
    var _m = useState(''), pdfFileName = _m[0], setPdfFileName = _m[1];
    var _o = useState(), relatedJobInformation = _o[0], setRelatedJobInformation = _o[1];
    var _p = useState(false), showPaymentFeedbackModal = _p[0], setShowPaymentFeedbackModal = _p[1];
    var _q = useState({ title: 'Thank you' }), paymentFeedbackMessage = _q[0], setPaymentFeedbackMessage = _q[1];
    var _r = useState(), bacsDetails = _r[0], setBacsDetails = _r[1];
    var _s = useState(null), stripePaymentIntent = _s[0], setStripePaymentIntent = _s[1];
    var _t = useState(false), showJobSignatureCaptureFeedbackModal = _t[0], setShowJobSignatureCaptureFeedbackModal = _t[1];
    var _u = useState(false), showJobSignatureCaptureErrorModal = _u[0], setShowJobSignatureCaptureErrorModal = _u[1];
    var _v = useState(false), showJobPaymentModal = _v[0], setShowJobPaymentModal = _v[1];
    var _w = useState(), acceptedSignatureImageData = _w[0], setAcceptedSignatureImageData = _w[1];
    var _x = useState(), satisfactionNoteSignatureImageData = _x[0], setSatisfactionNoteSignatureImageData = _x[1];
    var _y = useState(), jobPayments = _y[0], setJobPayments = _y[1];
    var _z = useState(), jobFinanceInformation = _z[0], setJobFinanceInformation = _z[1];
    var _0 = useState(), jobLineItems = _0[0], setJobLineItems = _0[1];
    var _1 = useState(), jobLineItemAttachments = _1[0], setJobLineItemAttachments = _1[1];
    var _2 = useState(), jobLineItemGroups = _2[0], setJobLineItemGroups = _2[1];
    var _3 = useState(), jobAttachments = _3[0], setJobAttachments = _3[1];
    var _4 = useState(false), isSignatureCaptureControlFocused = _4[0], setIsSignatureCaptureControlFocused = _4[1];
    var _5 = useState([]), financePlanConfigs = _5[0], setFinancePlanConfigs = _5[1];
    var _6 = useState(''), scrollDirection = _6[0], setScrollDirection = _6[1];
    var _7 = useState(false), isScrolledToTop = _7[0], setIsScrolledToTop = _7[1];
    var _8 = useState(false), isScrolledToBottom = _8[0], setIsScrolledToBottom = _8[1];
    var _9 = React.useState(false), declining = _9[0], setDeclining = _9[1];
    var _10 = React.useState(false), showDeclineJob = _10[0], setShowDeclineJob = _10[1];
    var _11 = useState(0), sliderValue = _11[0], setSliderValue = _11[1];
    var _12 = useState(0), depositAmount = _12[0], setDepositAmount = _12[1];
    var _13 = useState(''), bespokeFinanceError = _13[0], setBespokeFinanceError = _13[1];
    var _14 = useState(false), isStandardPaymentOpen = _14[0], setIsStandardPaymentOpen = _14[1];
    var _15 = useState(false), isFinancePaymentOpen = _15[0], setIsFinancePaymentOpen = _15[1];
    var _16 = useState(null), selectedFinancePlan = _16[0], setSelectedFinancePlan = _16[1];
    var _17 = useState(false), isSidebarClosed = _17[0], setIsSidebarClosed = _17[1];
    var signatureCaptureControlContainerReference = useRef(null);
    var financeAndSignatureCaptureControlContainerReference = useRef(null);
    var _18 = useState(false), hasCompletedInitialLoad = _18[0], setHasCompletedInitialLoad = _18[1];
    var previousHasCompletedInitialLoad = usePrevious(hasCompletedInitialLoad);
    var _19 = useState(null), jobError = _19[0], setJobError = _19[1];
    var _20 = useState(false), isApplyingForBespokeFinance = _20[0], setIsApplyingForBespokeFinance = _20[1];
    var _21 = useState(false), isGettingStripePaymentIntent = _21[0], setIsGettingStripePaymentIntent = _21[1];
    var previewToken = useMemo(function () {
        return getUrlSearchParam(location.search, 'previewToken');
    }, [location]);
    var customerId = useMemo(function () {
        return getUrlSearchParam(location.search, 'customerId');
    }, [location]);
    var updateQuote = useCallback(function (payload) {
        setIsLoading(true);
        agent.Auth.updateQuote(previewToken, payload).then(function (response) {
            setIsLoading(false);
            if (!response.error) {
                setJob(response.job);
                setJobLineItems(response.jobLineItems);
                setJobLineItemGroups(response.jobLineItemGroups);
                setPdfData(response.pdf);
                setStripePaymentIntent(response.paymentIntent || stripePaymentIntent);
                setFinancePlanConfigs(response.financePlans);
            }
        });
    }, [previewToken, stripePaymentIntent]);
    var shouldCollapseJobViewerHeader = useMemo(function () {
        return !isMobile && !isScrolledToTop && scrollDirection === 'down';
    }, [isScrolledToTop, scrollDirection]);
    var amountDue = useMemo(function () {
        if (!job || !jobPayments)
            return 0;
        if (isProposition) {
            return ((job.depositAmount || 0) -
                getAttemptedDepositPaymentValueFromJobPayments(jobPayments));
        }
        else {
            return (job.total - getTotalAttemptedPaymentValueFromJobPayments(jobPayments));
        }
    }, [job, jobPayments, isProposition]);
    var jobTotalAmountDue = useMemo(function () {
        if (!job || !jobPayments)
            return 0;
        return (job.total - getTotalAttemptedPaymentValueFromJobPayments(jobPayments));
    }, [job, jobPayments]);
    var minimumMonthlyPayment = useMemo(function () {
        if (job && (financePlanConfigs === null || financePlanConfigs === void 0 ? void 0 : financePlanConfigs.length)) {
            var minimumMonthlyCosts = financePlanConfigs
                .map(function (financePlanConfig) {
                return getMinimumMonthlyCost(job.total, financePlanConfig.maxDeposit, financePlanConfig.minAmount, financePlanConfig.interestRate, financePlanConfig.agreementDuration);
            })
                .sort(function (a, b) { return a - b; });
            // lowest monthly cost from finance plans
            return "\u00A3" + Math.ceil(minimumMonthlyCosts[0] / 100);
        }
        return null;
    }, [financePlanConfigs, job]);
    var interestRate = useMemo(function () {
        var _a;
        if (!(financePlanConfigs === null || financePlanConfigs === void 0 ? void 0 : financePlanConfigs.length)) {
            return 0;
        }
        var percentageDecimal = Number((_a = financePlanConfigs[0]) === null || _a === void 0 ? void 0 : _a.interestRate) || 0;
        return 100 * percentageDecimal;
    }, [financePlanConfigs]);
    var financePaymentTitle = useMemo(function () {
        return "" + (financePlanConfigs.length > 1 ? 'Finance' : interestRate + "% finance") + (minimumMonthlyPayment ? " from " + minimumMonthlyPayment + " per month" : '');
    }, [financePlanConfigs, minimumMonthlyPayment]);
    var jobType = useMemo(function () {
        if (!job)
            return null;
        return isProposition && isInvoice(job === null || job === void 0 ? void 0 : job.status) ? 'Job' : getJobType(job);
    }, [job, isProposition]);
    var isPaymentRequired = useMemo(function () {
        if (!job)
            return false;
        return isProposition
            ? isDepositPaymentRequired(job)
            : isInvoicePaymentRequired(job);
    }, [job, isProposition]);
    var isPaidByClient = useMemo(function () {
        if (!job || !jobPayments)
            return false;
        return isProposition
            ? isDepositAttemptedPaid(job, jobPayments)
            : isInvoiceAttemptedPaid(job, jobPayments);
    }, [job, jobPayments, isProposition]);
    var isConfirmedPaid = useMemo(function () {
        if (!job || !jobPayments)
            return false;
        return isProposition
            ? isDepositConfirmedPaid(job, jobPayments)
            : isInvoiceConfirmedPaid(job, jobPayments);
    }, [job, jobPayments, isProposition]);
    var pendingPaymentMethod = useMemo(function () {
        var _a, _b;
        if (isProposition) {
            return (_a = jobPayments === null || jobPayments === void 0 ? void 0 : jobPayments.find(function (x) {
                return x.isDepositPayment && !x.paymentCompletedConfirmationAt;
            })) === null || _a === void 0 ? void 0 : _a.paymentMethod;
        }
        else {
            return (_b = jobPayments === null || jobPayments === void 0 ? void 0 : jobPayments.find(function (x) {
                return !x.isDepositPayment && !x.paymentCompletedConfirmationAt;
            })) === null || _b === void 0 ? void 0 : _b.paymentMethod;
        }
    }, [jobPayments, isProposition]);
    var isPaidByBacs = useMemo(function () {
        if (!isPaidByClient)
            return false;
        return pendingPaymentMethod === PaymentMethodType.BACS;
    }, [isPaidByClient, pendingPaymentMethod]);
    var isPaidByStripe = useMemo(function () {
        if (!isPaidByClient)
            return false;
        return pendingPaymentMethod === PaymentMethodType.STRIPE;
    }, [isPaidByClient, pendingPaymentMethod]);
    var showStandardSignatureOption = useMemo(function () {
        if (!job)
            return false;
        return (isProposition &&
            !job.acceptedAt &&
            !job.declinedAt &&
            !hasAcceptedFinanceApplication(jobFinanceInformation));
    }, [job, isProposition, jobFinanceInformation]);
    var isJobSatisfactionNoteSignatureRequired = useMemo(function () {
        if (!job)
            return false;
        return (isCustomerPreview &&
            isSatisfactionNoteSignatureRequired(job, jobFinanceInformation));
    }, [job, jobFinanceInformation, isCustomerPreview]);
    // hide ctas if finance applicaiton has been submitted and pending approval/rejection or signing
    var hideButtons = useMemo(function () {
        return hasSubmittedFinanceApplication(jobFinanceInformation) &&
            ((!hasAcceptedFinanceApplication(jobFinanceInformation) &&
                !hasRejectedFinanceApplication(jobFinanceInformation)) ||
                !(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.customerAgreementSignedAt));
    }, [jobFinanceInformation]);
    var isZeroPercentFinanceEnabled = useMemo(function () {
        var _a, _b;
        // enabled on job, account is approved and subscription allows
        var jobHasZeroPercentEnabled = !!(job === null || job === void 0 ? void 0 : job.showBespokeFinancePlans);
        var accountIsZeroPercentApproved = !!((_b = (_a = account === null || account === void 0 ? void 0 : account.integrations) === null || _a === void 0 ? void 0 : _a.zeroPercentFinance) === null || _b === void 0 ? void 0 : _b.isApproved);
        var accountHasSubscriptionFeature = !!(accountAccessInformation &&
            accountAccessInformation.features.hasZeroPercentFinance);
        return (isProposition &&
            jobHasZeroPercentEnabled &&
            accountIsZeroPercentApproved &&
            accountHasSubscriptionFeature);
    }, [account, accountAccessInformation, isProposition, job]);
    // zero percent enabled, havent submitted application or submitted and declined
    var showFinanceApplyAndStandardSignatureOption = useMemo(function () {
        return job &&
            isZeroPercentFinanceEnabled &&
            (!hasSubmittedFinanceApplication(jobFinanceInformation) ||
                (hasSubmittedFinanceApplication(jobFinanceInformation) &&
                    hasRejectedFinanceApplication(jobFinanceInformation) &&
                    !job.declinedAt));
    }, [job, jobFinanceInformation, isZeroPercentFinanceEnabled]);
    var showFinanceOptions = useMemo(function () {
        if (!job)
            return false;
        return shouldShowFinanceOptionsToCustomer(job, jobPayments || [], jobFinanceInformation);
    }, [job, jobPayments, jobFinanceInformation]);
    var jobAttachmentsElement = useMemo(function () {
        var attachments = [];
        if (jobAttachments === null || jobAttachments === void 0 ? void 0 : jobAttachments.length) {
            attachments = __spreadArrays(jobAttachments);
        }
        if (businessAttachments && businessAttachments.length) {
            attachments = __spreadArrays(attachments, businessAttachments);
        }
        if (attachments.length) {
            return React.createElement(JobAttachments, { jobAttachments: attachments });
        }
    }, [businessAttachments, job, jobAttachments]);
    var declineJob = useCallback(function (declineReason) {
        setDeclining(true);
        agent.Auth.declineJob(previewToken, declineReason).then(function () {
            setDeclining(false);
            setShowDeclineJob(false);
            getJob();
        });
    }, [previewToken]);
    var getJob = useCallback(function (isInitialLoad) {
        if (isInitialLoad === void 0) { isInitialLoad = false; }
        setIsLoading(true);
        setJobError(null);
        if (previewToken && customerId) {
            agent.Auth.getJobPreview(previewToken, isProposition).then(function (response) {
                if (!response || response.error) {
                    setIsLoading(false);
                    setJobError(response.errors || response.statusText);
                    setAccount(response.account);
                }
                else {
                    setJob(response.job);
                    setJobLineItems(response.jobLineItems);
                    setJobLineItemAttachments(response.jobLineItemAttachments);
                    setJobLineItemGroups(response.jobLineItemGroups);
                    setJobAttachments(response.jobAttachments);
                    setAccount(response.account);
                    setAccountAccessInformation(response.accountAccessInformation);
                    setCustomer(response.customer);
                    setPdfData(response.pdf);
                    setPdfFileName(response.fileName);
                    setTerms(response.terms);
                    setBusinessAttachments(response.businessAttachments);
                    setRelatedJobInformation(response.relatedJobInformation);
                    setAcceptedSignatureImageData(response.acceptedSignatureImageData);
                    setSatisfactionNoteSignatureImageData(response.satisfactionNoteSignatureImageData);
                    setJobFinanceInformation(response.jobFinanceInformation);
                    setBacsDetails(response.bacsDetails);
                    setIsCustomerPreview(response.isCustomerPreview);
                    setJobPayments(response.jobPayments);
                    setIsLoading(false);
                    setFinancePlanConfigs(response.financePlans);
                    if (isInitialLoad) {
                        setHasCompletedInitialLoad(true);
                    }
                }
            });
        }
    }, [previewToken, customerId, isProposition]);
    var onSignatureCaptureComplete = useCallback(function (error) {
        getJob();
        if (!error) {
            setShowJobSignatureCaptureFeedbackModal(true);
        }
        else {
            setShowJobSignatureCaptureErrorModal(true);
        }
    }, [getJob]);
    var focusSignatureCaptureControl = useCallback(function () {
        var _a;
        var signatureCaptureOffsetTop = (_a = signatureCaptureControlContainerReference === null || signatureCaptureControlContainerReference === void 0 ? void 0 : signatureCaptureControlContainerReference.current) === null || _a === void 0 ? void 0 : _a.offsetTop;
        if (signatureCaptureOffsetTop) {
            scrollToWithAnimation(document.documentElement, 'scrollTop', signatureCaptureOffsetTop, 300, 'easeInOutCirc', function () {
                setIsSignatureCaptureControlFocused(true);
                setTimeout(function () { return setIsSignatureCaptureControlFocused(false); }, 3000);
            });
        }
    }, [signatureCaptureControlContainerReference]);
    var focusFinanceAndSignatureCaptureControl = useCallback(function () {
        var _a;
        var financeAndSignatureCaptureOffsetTop = (_a = financeAndSignatureCaptureControlContainerReference === null || financeAndSignatureCaptureControlContainerReference === void 0 ? void 0 : financeAndSignatureCaptureControlContainerReference.current) === null || _a === void 0 ? void 0 : _a.offsetTop;
        if (financeAndSignatureCaptureOffsetTop) {
            scrollToWithAnimation(document.documentElement, 'scrollTop', financeAndSignatureCaptureOffsetTop, 300, 'easeInOutCirc', function () {
                setIsSignatureCaptureControlFocused(true);
                setTimeout(function () { return setIsSignatureCaptureControlFocused(false); }, 3000);
            });
        }
    }, [financeAndSignatureCaptureControlContainerReference]);
    var signatureCaptureCtaElement = useMemo(function () {
        if (!job)
            return;
        if (!showStandardSignatureOption)
            return;
        return (React.createElement(JobOverviewCta, { onClick: focusSignatureCaptureControl }, "Sign to accept"));
    }, [job, showStandardSignatureOption, focusSignatureCaptureControl]);
    var financeAndSignatureCaptureCtaElement = useMemo(function () {
        if (!job) {
            return;
        }
        if (!showFinanceApplyAndStandardSignatureOption) {
            return;
        }
        return (React.createElement(JobOverviewCta, { onClick: focusFinanceAndSignatureCaptureControl }, "See payment options"));
    }, [
        job,
        showFinanceApplyAndStandardSignatureOption,
        focusFinanceAndSignatureCaptureControl,
    ]);
    var satisfactionNoteSignatureCaptureCtaElement = useMemo(function () {
        if (!jobFinanceInformation)
            return;
        if (!isJobSatisfactionNoteSignatureRequired)
            return;
        return (React.createElement(JobOverviewCta, { onClick: focusSignatureCaptureControl }, "Confirm job satisfaction"));
    }, [
        jobFinanceInformation,
        isJobSatisfactionNoteSignatureRequired,
        focusSignatureCaptureControl,
    ]);
    var paymentCtaElement = useMemo(function () {
        if (!job)
            return;
        if (showStandardSignatureOption)
            return;
        if (isPaymentRequired && !isPaidByClient) {
            return (React.createElement(JobOverviewCta, { onClick: function () { return setShowJobPaymentModal(true); }, showPaymentMethodIcons: !!(job === null || job === void 0 ? void 0 : job.showStripePaymentOption) }, isProposition ? 'Pay deposit now' : 'Pay now'));
        }
    }, [
        job,
        isProposition,
        isPaymentRequired,
        isPaidByClient,
        showStandardSignatureOption,
    ]);
    var financeOptionsOverlayTriggerElement = useMemo(function () {
        if (!showFinanceOptions)
            return null;
        return (React.createElement(JobOverviewCta, { showPaymentMethodIcons: false, onClick: function () { return setIsFinanceOptionsOverlayActive(true); } }, "Finance available"));
    }, [showFinanceOptions]);
    var isQuoteExpired = useMemo(function () { return isProposition && moment(job === null || job === void 0 ? void 0 : job.validUntil).isBefore(moment.now()); }, [isProposition, job]);
    var signatureCaptureControlElement = useMemo(function () {
        if (!job || !showStandardSignatureOption) {
            return;
        }
        return (React.createElement("div", { ref: signatureCaptureControlContainerReference, className: "job-signature-capture-control-container" },
            React.createElement(JobSignatureCaptureControl, { displaySignHerePrompt: isSignatureCaptureControlFocused, isPaymentRequired: isPaymentRequired, isQuoteExpired: isQuoteExpired, jobType: jobType, onDecline: function () { return setShowDeclineJob(true); }, onSignatureCaptureComplete: onSignatureCaptureComplete, previewToken: previewToken, showDeclineOption: isProposition && !job.acceptedAt && !job.declinedAt, terms: terms })));
    }, [
        isPaymentRequired,
        isQuoteExpired,
        isSignatureCaptureControlFocused,
        job,
        jobType,
        onSignatureCaptureComplete,
        previewToken,
        showStandardSignatureOption,
        terms,
    ]);
    var satisfactionNoteSignatureCaptureControlElement = useMemo(function () {
        if (!jobFinanceInformation)
            return;
        if (!isJobSatisfactionNoteSignatureRequired)
            return;
        return (React.createElement("div", { ref: signatureCaptureControlContainerReference },
            React.createElement(SatisfactionNoteSignatureCaptureControl, { displaySignHerePrompt: isSignatureCaptureControlFocused, previewToken: previewToken, companyName: account === null || account === void 0 ? void 0 : account.companyName, onResponseCaptureComplete: getJob, rejectedAt: jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.satisfactionNoteRejectedAt, agreementDuration: jobFinanceInformation.agreementDuration, lenderName: jobFinanceInformation.type })));
    }, [
        account,
        jobFinanceInformation,
        previewToken,
        signatureCaptureControlContainerReference,
        isJobSatisfactionNoteSignatureRequired,
        isSignatureCaptureControlFocused,
        getJob,
    ]);
    var applyForBespokeFinance = useCallback(function () {
        setIsApplyingForBespokeFinance(true);
        agent.Auth.applyForBespokeFinance(previewToken, selectedFinancePlan, depositAmount).then(function (resp) {
            setIsApplyingForBespokeFinance(false);
            if (resp && resp.redirectUrl) {
                // redirect customer to apply for finance
                window.location.href = resp.redirectUrl;
            }
            else {
                // show error if there was an issue returning url
                setBespokeFinanceError('Sorry, there was an error, please try again.');
            }
        });
    }, [depositAmount, previewToken, selectedFinancePlan]);
    var disableBespokeFinanceApplyButton = useMemo(function () {
        return isApplyingForBespokeFinance ||
            !job ||
            !selectedFinancePlan ||
            !isCreditRequestValid(depositAmount, job.total, selectedFinancePlan) ||
            !!(jobFinanceInformation === null || jobFinanceInformation === void 0 ? void 0 : jobFinanceInformation.rejectedAt);
    }, [
        depositAmount,
        isApplyingForBespokeFinance,
        job,
        jobFinanceInformation,
        selectedFinancePlan,
    ]);
    var bespokeFinanceApplyElement = useMemo(function () {
        if (!job || !financePlanConfigs.length) {
            return;
        }
        return (React.createElement("div", { className: "bespoke-finance-breakdown-container" + (isSmallView ? ' small-view' : ''), ref: financeBreakdownRef },
            React.createElement(JobOverviewFinancePlans, { job: job, companyName: (account === null || account === void 0 ? void 0 : account.companyName) || '', financePlans: financePlanConfigs, onInputChange: function (inputVal) { return setDepositAmount(inputVal); }, onSliderChange: function (sliderVal) { return setSliderValue(sliderVal); }, inputValue: depositAmount, sliderValue: sliderValue, selectedFinancePlan: selectedFinancePlan, onSelectedFinancePlanChange: function (financePlan) {
                    return setSelectedFinancePlan(financePlan !== selectedFinancePlan ? financePlan : null);
                }, isApplying: isApplyingForBespokeFinance, onApply: applyForBespokeFinance, isDisabled: disableBespokeFinanceApplyButton }),
            bespokeFinanceError && (React.createElement("div", { className: "apply-bespoke-finance error" }, bespokeFinanceError))));
    }, [
        account,
        depositAmount,
        financePlanConfigs,
        job,
        sliderValue,
        bespokeFinanceError,
        isSmallView,
        selectedFinancePlan,
        isApplyingForBespokeFinance,
        applyForBespokeFinance,
        disableBespokeFinanceApplyButton,
    ]);
    var standardAndFinancePaymentMethodsElement = useMemo(function () {
        return (React.createElement("div", { ref: financeAndSignatureCaptureControlContainerReference, className: "standard-and-finance-panels-wrapper" },
            React.createElement(CollapsiblePanel, { title: "Standard payment", isOpen: !!isStandardPaymentOpen, onOpen: function () {
                    setIsStandardPaymentOpen(true);
                    // close finance
                    setIsFinancePaymentOpen(false);
                }, onClose: function () {
                    setIsStandardPaymentOpen(false);
                }, styleVariant: CollapsiblePanelStyleVariant.MINIMAL }, signatureCaptureControlElement),
            React.createElement(CollapsiblePanel, { title: financePaymentTitle, isOpen: isFinancePaymentOpen, onOpen: function () {
                    setIsFinancePaymentOpen(true);
                    // close standard
                    setIsStandardPaymentOpen(false);
                }, onClose: function () {
                    setIsFinancePaymentOpen(false);
                }, delayMs: 150, styleVariant: CollapsiblePanelStyleVariant.MINIMAL }, bespokeFinanceApplyElement)));
    }, [
        financePaymentTitle,
        isFinancePaymentOpen,
        isStandardPaymentOpen,
        signatureCaptureControlElement,
        bespokeFinanceApplyElement,
    ]);
    var jobOverviewCtaElement = useMemo(function () {
        if (!job) {
            return;
        }
        var ctaElement = null;
        // Show zero percent finance apply button and breakdown
        if (isJobSatisfactionNoteSignatureRequired) {
            ctaElement = satisfactionNoteSignatureCaptureControlElement;
        }
        else if (showFinanceApplyAndStandardSignatureOption) {
            ctaElement = standardAndFinancePaymentMethodsElement;
        }
        else if (hideButtons) {
            return;
        }
        else if (showStandardSignatureOption) {
            // if not zero percent
            ctaElement = signatureCaptureControlElement;
        }
        else {
            ctaElement = paymentCtaElement;
        }
        return (React.createElement("div", { className: "cta-container" },
            financeOptionsOverlayTriggerElement,
            ctaElement));
    }, [
        job,
        isJobSatisfactionNoteSignatureRequired,
        satisfactionNoteSignatureCaptureControlElement,
        showFinanceApplyAndStandardSignatureOption,
        standardAndFinancePaymentMethodsElement,
        hideButtons,
        showStandardSignatureOption,
        signatureCaptureControlElement,
        paymentCtaElement,
        financeOptionsOverlayTriggerElement,
    ]);
    var jobHeaderCtaElement = useMemo(function () {
        // show zero percent and apply - different button
        if (isJobSatisfactionNoteSignatureRequired) {
            return satisfactionNoteSignatureCaptureCtaElement;
        }
        else if (showFinanceApplyAndStandardSignatureOption) {
            return financeAndSignatureCaptureCtaElement;
        }
        else if (hideButtons) {
            return;
        }
        else if (showStandardSignatureOption) {
            return signatureCaptureCtaElement;
        }
        else {
            return paymentCtaElement;
        }
    }, [
        isJobSatisfactionNoteSignatureRequired,
        satisfactionNoteSignatureCaptureCtaElement,
        showFinanceApplyAndStandardSignatureOption,
        financeAndSignatureCaptureCtaElement,
        hideButtons,
        showStandardSignatureOption,
        signatureCaptureCtaElement,
        paymentCtaElement,
    ]);
    var jobPaymentFeedbackModalElement = useMemo(function () {
        return (React.createElement(Modal, { isOpen: showPaymentFeedbackModal, onClose: function () { return setShowPaymentFeedbackModal(false); }, title: paymentFeedbackMessage.title, className: "job-payment-feedback-modal", size: "xs" }, paymentFeedbackMessage.body && React.createElement("p", null, paymentFeedbackMessage.body)));
    }, [showPaymentFeedbackModal, paymentFeedbackMessage]);
    var jobOverviewHeaderElement = useMemo(function () {
        if (!job || !account || !customer)
            return;
        return (React.createElement(JobOverviewHeader, { account: account, customer: customer, job: job, jobFinanceInformation: jobFinanceInformation }));
    }, [account, customer, job, jobFinanceInformation]);
    var jobOverviewBusinessDetailsElement = useMemo(function () {
        if (!job || !account)
            return;
        return (React.createElement(JobOverviewBusinessDetails, { account: account, jobFinanceInformation: jobFinanceInformation, job: job }));
    }, [account, job, jobFinanceInformation]);
    var sidebarElement = useMemo(function () {
        if (!job || !account || !customer)
            return;
        return (React.createElement(JobOverviewTimeline, { acceptedSignatureImageData: acceptedSignatureImageData, companyName: account.companyName, customer: customer, job: job, jobFinanceInformation: jobFinanceInformation, jobPayments: jobPayments || [], satisfactionNoteSignatureImageData: satisfactionNoteSignatureImageData }));
    }, [
        acceptedSignatureImageData,
        account,
        job,
        jobFinanceInformation,
        jobPayments,
        satisfactionNoteSignatureImageData,
    ]);
    var jobOverviewElement = useMemo(function () {
        var isEditable = false;
        var forceDisplayAsProposition = false;
        var onUpdate = function (payload) {
            return;
        };
        if (!job || !account || !customer)
            return;
        if (isProposition) {
            if (isInvoice(job.status)) {
                forceDisplayAsProposition = true;
            }
            else if (!job.acceptedAt &&
                !hasSubmittedFinanceApplication(jobFinanceInformation)) {
                isEditable = true;
                onUpdate = updateQuote;
            }
        }
        return (React.createElement(JobOverview, { acceptedSignatureImageData: acceptedSignatureImageData, account: account, callToAction: jobOverviewCtaElement, customer: customer, isEditable: isEditable, job: job, jobFinanceInformation: jobFinanceInformation, jobLineItems: jobLineItems || [], jobLineItemAttachments: jobLineItemAttachments || [], jobLineItemGroups: jobLineItemGroups || [], jobPayments: jobPayments || [], onUpdate: onUpdate, satisfactionNoteSignatureImageData: satisfactionNoteSignatureImageData }));
    }, [
        acceptedSignatureImageData,
        account,
        isProposition,
        job,
        jobFinanceInformation,
        jobLineItemAttachments,
        jobLineItemGroups,
        jobLineItems,
        jobOverviewCtaElement,
        jobPayments,
        updateQuote,
    ]);
    var jobStatus = useMemo(function () {
        if (isPaymentRequired) {
            var message = '';
            if (isProposition && (job === null || job === void 0 ? void 0 : job.acceptedAt)) {
                message = 'DEPOSIT DUE';
            }
            if (isPaidByClient) {
                if (!isConfirmedPaid) {
                    if (isPaidByBacs) {
                        message = "Awaiting confirmation of payment from " + (account === null || account === void 0 ? void 0 : account.companyName);
                    }
                    if (isPaidByStripe) {
                        message = 'Processing payment...';
                    }
                }
                else {
                    if (isProposition) {
                        message = "Deposit fully paid";
                    }
                    else {
                        message = "Invoice fully paid";
                    }
                }
            }
            return message;
        }
        // TODO accepted
    }, [
        account,
        isConfirmedPaid,
        isPaidByClient,
        isPaymentRequired,
        isProposition,
        job,
    ]);
    var onPaymentComplete = useCallback(function (feedbackMessage) {
        setShowJobPaymentModal(false);
        getJob();
        if (feedbackMessage) {
            setPaymentFeedbackMessage(feedbackMessage);
            setShowPaymentFeedbackModal(true);
        }
    }, [getJob]);
    var getStripePaymentIntent = useCallback(function () {
        if (job === null || job === void 0 ? void 0 : job.showStripePaymentOption) {
            // clear any current payment intent
            setStripePaymentIntent(null);
            setIsGettingStripePaymentIntent(true);
            agent.Auth.getPaymentIntent(stripePaymentIntent ? stripePaymentIntent === null || stripePaymentIntent === void 0 ? void 0 : stripePaymentIntent.id : null, previewToken, isProposition).then(function (response) {
                setIsGettingStripePaymentIntent(false);
                if (!response.error) {
                    setStripePaymentIntent(response);
                }
            });
        }
    }, [isProposition, job, previewToken, stripePaymentIntent]);
    var jobPaymentModalElement = useMemo(function () {
        if (!job) {
            return;
        }
        return (React.createElement(JobPaymentModal, { isOpen: showJobPaymentModal, onClose: function () { return setShowJobPaymentModal(false); }, isDemo: job === null || job === void 0 ? void 0 : job.isDemo, customer: customer, account: account, previewToken: previewToken, isProposition: isProposition, amountDue: amountDue, bacsDetails: bacsDetails, stripePaymentIntent: stripePaymentIntent, onPaymentComplete: onPaymentComplete, jobTotalAmountDue: jobTotalAmountDue, getStripePaymentIntent: getStripePaymentIntent, showStripePayment: job === null || job === void 0 ? void 0 : job.showStripePaymentOption, isGettingStripePaymentIntent: isGettingStripePaymentIntent, jobContactId: job.contactId }));
    }, [
        account,
        amountDue,
        bacsDetails,
        customer,
        getStripePaymentIntent,
        isGettingStripePaymentIntent,
        isProposition,
        job,
        onPaymentComplete,
        previewToken,
        showJobPaymentModal,
        stripePaymentIntent,
    ]);
    var declineJobModalElement = useMemo(function () {
        return (React.createElement(DeclineJobModal, { isOpen: showDeclineJob, onClose: function () { return setShowDeclineJob(false); }, isSubmitting: declining, onSubmit: declineJob }));
    }, [showDeclineJob, declining, declineJob]);
    var jobSignatureCaptureFeedbackModalElement = useMemo(function () {
        return (React.createElement(JobSignatureCaptureFeedbackModal, { isOpen: showJobSignatureCaptureFeedbackModal, amountDue: amountDue, isPaymentRequired: isPaymentRequired, onRequestPayNow: function () {
                setShowJobPaymentModal(true);
                setShowJobSignatureCaptureFeedbackModal(false);
            }, onClose: function () { return setShowJobSignatureCaptureFeedbackModal(false); } }));
    }, [
        showJobSignatureCaptureFeedbackModal,
        job,
        jobType,
        isPaymentRequired,
        amountDue,
    ]);
    var jobSignatureCaptureErrorModalElement = useMemo(function () {
        return (React.createElement(JobSignatureCaptureErrorModal, { isOpen: showJobSignatureCaptureErrorModal, onClose: function () { return setShowJobSignatureCaptureErrorModal(false); } }));
    }, [showJobSignatureCaptureErrorModal]);
    useEffect(function () {
        window.addEventListener('resize', onWindowResize);
        getJob(true);
        return function () {
            window.removeEventListener('resize', onWindowResize);
        };
    }, []);
    useEffect(function () {
        if (isPaidByStripe && !isConfirmedPaid) {
            setTimeout(getJob, 5000);
        }
    }, [isPaidByStripe, isConfirmedPaid]);
    var shouldShowPaymentModal = useMemo(function () {
        if (!job || !jobPayments) {
            return false;
        }
        var isAtPaymentStage = !isJobSatisfactionNoteSignatureRequired &&
            !showFinanceApplyAndStandardSignatureOption &&
            !hideButtons &&
            !showStandardSignatureOption;
        var isPaymentModalRequired = isProposition &&
            isDepositPaymentRequired(job) &&
            !isDepositAttemptedPaid(job, jobPayments);
        return isAtPaymentStage && isPaymentModalRequired;
    }, [
        hideButtons,
        isJobSatisfactionNoteSignatureRequired,
        isProposition,
        job,
        jobPayments,
        showFinanceApplyAndStandardSignatureOption,
        showStandardSignatureOption,
    ]);
    useEffect(function () {
        if (!previousHasCompletedInitialLoad &&
            hasCompletedInitialLoad &&
            shouldShowPaymentModal) {
            // show payment modal on page load if customer is at payment stage on quote
            setShowJobPaymentModal(true);
        }
    }, [
        hasCompletedInitialLoad,
        previousHasCompletedInitialLoad,
        shouldShowPaymentModal,
    ]);
    useScrollPosition(function (_a) {
        var prevPos = _a.prevPos, currPos = _a.currPos;
        if ((currPos === null || currPos === void 0 ? void 0 : currPos.y) > (prevPos === null || prevPos === void 0 ? void 0 : prevPos.y)) {
            setScrollDirection('up');
        }
        else if ((currPos === null || currPos === void 0 ? void 0 : currPos.y) < (prevPos === null || prevPos === void 0 ? void 0 : prevPos.y)) {
            setScrollDirection('down');
        }
        else {
            setScrollDirection('');
        }
        setIsScrolledToTop(getIsScrolledToTop((currPos === null || currPos === void 0 ? void 0 : currPos.y) || 0));
        setIsScrolledToBottom(getIsScrolledToBottom((currPos === null || currPos === void 0 ? void 0 : currPos.y) || 0));
    }, []);
    var onWindowResize = useCallback(function () {
        setScrollDirection('');
    }, []);
    var viewFinanceOptions = useCallback(function () {
        if (job === null || job === void 0 ? void 0 : job.isDemo) {
            alert('Finance application unavailable as this is a DEMO quote.');
            return;
        }
        agent.Auth.trackFinanceClick(previewToken);
        window.open(FINANCE_URL + "?customerId=" + customerId + "&account_uuid=" + (account === null || account === void 0 ? void 0 : account.uuid), '_blank');
    }, [previewToken, customerId, account, job]);
    var goToSelinaFinanceApply = useCallback(function () {
        if (job === null || job === void 0 ? void 0 : job.isDemo) {
            alert('Finance application unavailable as this is a DEMO quote.');
            return;
        }
        agent.Auth.trackSelinaFinanceClick(previewToken);
        window.open('https://quote.selinaadvance.com/?utm_source=affiliates&utm_medium=cpc&utm_campaign=_consumer_referral___payaca_main_', '_blank');
    }, [previewToken, job]);
    var jobErrorMessage = useMemo(function () {
        // TODO: improve error specifics i.e. 404, connected to internet
        if (jobError === 'INACTIVE_JOB') {
            return "Sorry, this version is no longer valid, if you're expecting an update please check your inbox for a new version.";
        }
        else if (jobError === 'ARCHIVED_JOB') {
            return 'Sorry, this job does not exist.';
        }
        else {
            // preview token is not related to a job
            return 'Sorry, there was an error retrieving this page';
        }
    }, [jobError]);
    var supportMessage = useMemo(function () {
        return (account === null || account === void 0 ? void 0 : account.contactNumber) || (account === null || account === void 0 ? void 0 : account.email)
            ? "Please " + (account.contactNumber ? "contact us on " + account.contactNumber + " " : '') + (account.email && account.contactNumber ? ' or ' : '') + " " + (account.email ? "email us at " + account.email : '') + " if you need any further assistance."
            : '';
    }, [account]);
    var jobReference = useMemo(function () {
        if (!job) {
            return '';
        }
        if (job.customReference) {
            return job.customReference;
        }
        return "" + job.reference;
    }, [job]);
    var jobTitle = useMemo(function () {
        if (job) {
            return getJobType(job) + " #" + jobReference;
        }
        return null;
    }, [job, jobReference]);
    if (isLoading) {
        return React.createElement(LoaderOverlay, null);
    }
    if (jobError) {
        return (React.createElement("div", { className: "job-page error" },
            React.createElement("div", { className: "page-inner" },
                React.createElement(ContentPanel, null,
                    React.createElement(React.Fragment, null,
                        (account === null || account === void 0 ? void 0 : account.logoUrl) && React.createElement("img", { src: account === null || account === void 0 ? void 0 : account.logoUrl }),
                        React.createElement("p", null, jobErrorMessage),
                        supportMessage && React.createElement("p", null, supportMessage))))));
    }
    return (React.createElement("div", { className: "job-page" },
        React.createElement(JobViewerHeader, { fileName: pdfFileName, hideRelatedJobInformation: !!(job === null || job === void 0 ? void 0 : job.showBespokeFinancePlans), isCollapsed: shouldCollapseJobViewerHeader, jobOverviewCta: jobHeaderCtaElement, jobTitle: jobTitle, pdfData: pdfData, relatedJobInformation: relatedJobInformation }),
        React.createElement("div", { className: "job-overview-wrapper" + (isSidebarClosed ? ' sidebar-closed' : '') },
            React.createElement("div", { className: "job-overview-inner-wrapper" },
                React.createElement("div", { className: "job-overview-status" }, jobStatus && (React.createElement(Badge, { colour: "#263e59", backgroundColour: "#fabb00" }, jobStatus))),
                React.createElement("div", { className: "job-overview-main" },
                    jobOverviewHeaderElement,
                    jobOverviewElement,
                    jobOverviewBusinessDetailsElement,
                    jobAttachmentsElement,
                    account && React.createElement(JobOverviewFooter, { account: account }))),
            React.createElement("div", { className: "job-overview-side-wrapper" },
                React.createElement("div", { className: "job-overview-side" }, sidebarElement))),
        showFinanceOptions && (React.createElement(FinanceOptionsOverlay, { isActive: isFinanceOptionsOverlayActive, onClose: function () { return setIsFinanceOptionsOverlayActive(false); }, hasSimpleFinanceOption: !!(job === null || job === void 0 ? void 0 : job.showFinanceOptions), hasSelinaFinanceOption: !!(job === null || job === void 0 ? void 0 : job.showSelinaFinance), goToSimpleFinanceApply: viewFinanceOptions, goToSelinaFinanceApply: goToSelinaFinanceApply })),
        jobSignatureCaptureFeedbackModalElement,
        jobSignatureCaptureErrorModalElement,
        jobPaymentModalElement,
        jobPaymentFeedbackModalElement,
        declineJobModalElement));
};
export default Job;
