import React, { useMemo } from 'react';
import { SignatureCapture } from '../../components';
import './SignatureCaptureAndTerms.sass';
var SignatureCaptureAndTerms = function (_a) {
    var _b = _a.displaySignHerePrompt, displaySignHerePrompt = _b === void 0 ? false : _b, signatureCanvasReference = _a.signatureCanvasReference, terms = _a.terms, jobType = _a.jobType, setIsSignaturePresent = _a.setIsSignaturePresent;
    var shouldRenderTerms = useMemo(function () {
        return !!(terms === null || terms === void 0 ? void 0 : terms.length);
    }, [terms]);
    return (React.createElement("div", { className: "signature-capture-and-terms" },
        React.createElement(SignatureCapture, { displaySignHerePrompt: displaySignHerePrompt, signatureCanvasReference: signatureCanvasReference, setIsSignaturePresent: setIsSignaturePresent }),
        shouldRenderTerms && (React.createElement("div", { className: "terms" },
            React.createElement("div", { className: "terms-heading" },
                "By signing you confirm that you would like to proceed with the details of this ",
                jobType.toLowerCase(),
                " and agree to our",
                ' ',
                terms.map(function (term, index) {
                    return (React.createElement("span", { key: "terms-" + index },
                        React.createElement("a", { href: term.termUrl, target: "_blank", rel: "noopener noreferrer" },
                            React.createElement("span", null, term.fileName)),
                        index !== terms.length - 1 && React.createElement("span", null, ", ")));
                }),
                ".")))));
};
export default SignatureCaptureAndTerms;
