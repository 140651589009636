import React, { useState, useEffect } from 'react';
import { LoaderOverlay, SubsidisedFinanceInformationOverview, } from '@payaca/components';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import agent from '../../agent';
import './SubsidisedFinanceInformationOverviewPage.sass';
var SubsidisedFinanceInformationOverviewPage = function () {
    var subsidisedFinanceInformationId = getUrlSearchParam(window.location.search, 'subsidisedFinanceInformationId');
    var token = getUrlSearchParam(window.location.search, 'token');
    var _a = useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = useState(), businessInformation = _c[0], setBusinessInformation = _c[1];
    var _d = useState(), financialInformation = _d[0], setFinancialInformation = _d[1];
    var _e = useState(), regulatoryRequirements = _e[0], setRegulatoryRequirements = _e[1];
    var _f = useState(), additionalInformation = _f[0], setAdditionalInformation = _f[1];
    var _g = useState(), authorityToSearch = _g[0], setAuthorityToSearch = _g[1];
    var _h = useState(), supportingDocuments = _h[0], setSupportingDocuments = _h[1];
    useEffect(function () {
        setIsLoading(true);
        agent.Auth.getSubsidisedFinanceInformation(subsidisedFinanceInformationId, token)
            .then(function (response) {
            if (response) {
                setBusinessInformation(response.businessInformation);
                setFinancialInformation(response.financialInformation);
                setAdditionalInformation(response.additionalInformation);
                setRegulatoryRequirements(response.regulatoryRequirements);
                setAuthorityToSearch(response.authorityToSearch);
                setSupportingDocuments(response.supportingDocuments);
            }
            else {
                setError(new Error());
            }
        })
            .catch(function (error) {
            setError(error);
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, []);
    if (isLoading) {
        return React.createElement(LoaderOverlay, null);
    }
    return (React.createElement("div", { className: "subsidised-finance-information-overview-page" },
        !error && (React.createElement(SubsidisedFinanceInformationOverview, { businessInformation: businessInformation, financialInformation: financialInformation, additionalInformation: additionalInformation, authorityToSearch: authorityToSearch, regulatoryRequirements: regulatoryRequirements, supportingDocuments: supportingDocuments })),
        error && React.createElement("p", null, "Something went wrong.")));
};
export default SubsidisedFinanceInformationOverviewPage;
