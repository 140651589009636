import React from 'react';
import './VisitRelatedJobLink.sass';
var VisitRelatedJobLink = function (_a) {
    var jobType = _a.jobType, previewUrl = _a.previewUrl;
    return (React.createElement("a", { className: "visit-related-job-link", href: previewUrl, target: "_blank", rel: "noopener noreferrer" },
        "View related ",
        jobType.toLowerCase(),
        ' '));
};
export default VisitRelatedJobLink;
