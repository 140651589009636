import superagent from 'superagent';

const API_BASE = process.env.REACT_APP_API_HOST || '/api';

const responseBody = (res) => res.body;
const errorBody = (err) => {
  return {
    statusText: err.response?.statusText,
    statusCode: err.response?.statusCode,
    errors: err.response?.body?.errors,
    error: true,
    account: err.response?.body?.account,
  };
};

let token;
const setToken = (_token) => {
  token = _token;
  return token;
};

const tokenPlugin = (req) => {
  if (token) {
    req.set('authorization', `Bearer ${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent.del(`${API_BASE}${url}`).use(tokenPlugin).then(responseBody),
  get: (url) => {
    return superagent
      .get(`${API_BASE}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorBody);
  },
  put: (url, body) =>
    superagent
      .put(`${API_BASE}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorBody),
  post: (url, body) =>
    superagent
      .post(`${API_BASE}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorBody),
};

export const Auth = {
  getJobOvervewInformation: (token) => {
    return superagent
      .get(`${API_BASE}/jobs/overview_information?previewToken=${token}`)
      .then(responseBody);
  },
  updateQuote: (token, update) =>
    requests.put('/jobs/update_quote_with_token', { token, update }),
  getJobPreview: (previewToken, isProposition) =>
    requests.get(
      `/jobs/preview_email_with_token_v3?previewToken=${previewToken}&type=${
        isProposition ? 'quote' : 'invoice'
      }`
    ),
  getSubsidisedFinanceInformation: (subsidisedFinanceInformationId, token) => {
    return superagent
      .get(
        `${API_BASE}/subsidised_finance_information/data_for_overview?subsidisedFinanceInformationId=${subsidisedFinanceInformationId}&token=${token}`
      )
      .then(responseBody);
  },
  acceptQuote: (previewToken, sigImage) =>
    requests.post('/jobs/accept_job_with_token_v2', {
      previewToken,
      sigImage,
    }),
  declineJob: (previewToken, declineReason) =>
    requests.post('/jobs/decline_job_with_token', {
      previewToken,
      declineReason,
    }),
  signSatisfactionNote: (previewToken, signatureImageData) =>
    requests.post('/job_finance_applications/sign_satisfaction_note', {
      previewToken,
      signatureImageData,
    }),
  rejectSatisfactionNote: (previewToken) =>
    requests.post('/job_finance_applications/reject_satisfaction_note', {
      previewToken,
    }),
  recordQuoteDepositBACSPayment: (previewToken, paymentValue) =>
    requests.post('/jobs/record_quote_bacs_payment_with_token', {
      previewToken,
      paymentValue,
    }),
  recordInvoiceBACSPayment: (previewToken, paymentValue) =>
    requests.post('/jobs/record_invoice_bacs_payment_with_token', {
      previewToken,
      paymentValue,
    }),
  markQuoteDepositStripePaymentAttempted: (previewToken, paymentIntent) =>
    requests.post('/jobs/mark_quote_deposit_stripe_attempted_with_token', {
      previewToken,
      paymentIntent,
    }),
  markInvoiceStripePaymentAttempted: (previewToken, paymentIntent) =>
    requests.post('/jobs/mark_invoice_stripe_attempted_with_token', {
      previewToken,
      paymentIntent,
    }),
  trackFinanceClick: (previewToken) =>
    requests.post('/jobs/track_finance_click_with_token', { previewToken }),
  trackSelinaFinanceClick: (previewToken) =>
    requests.post('/jobs/track_selina_finance_click_with_token', {
      previewToken,
    }),
  // customer applying for bespoke finance, returns a redirect url
  applyForBespokeFinance: (previewToken, financePlanConfig, depositAmount) =>
    requests.post('/job_finance_applications', {
      previewToken,
      financePlanConfig,
      depositAmount,
    }),
  // get stripe payment intent for a job
  getPaymentIntent: (paymentIntentId, previewToken, isProposition) =>
    requests.get(
      `/jobs/payment_intent?id=${encodeURIComponent(
        paymentIntentId || ''
      )}&previewToken=${previewToken}&type=${
        isProposition ? 'quote' : 'invoice'
      }`
    ),
};

export default { Auth, setToken };
