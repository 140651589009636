import React, { useMemo } from 'react';
import { JobPaymentControl } from '..';
import { Modal } from '@payaca/components/modal';
import './JobPaymentModal.sass';
var JobPaymentModal = function (_a) {
    var account = _a.account, amountDue = _a.amountDue, bacsDetails = _a.bacsDetails, customer = _a.customer, getStripePaymentIntent = _a.getStripePaymentIntent, _b = _a.isDemo, isDemo = _b === void 0 ? false : _b, isGettingStripePaymentIntent = _a.isGettingStripePaymentIntent, isOpen = _a.isOpen, isProposition = _a.isProposition, jobContactId = _a.jobContactId, jobTotalAmountDue = _a.jobTotalAmountDue, onClose = _a.onClose, onPaymentComplete = _a.onPaymentComplete, previewToken = _a.previewToken, showStripePayment = _a.showStripePayment, stripePaymentIntent = _a.stripePaymentIntent;
    var title = useMemo(function () {
        return isProposition
            ? 'Please make your deposit payment'
            : 'Please make payment';
    }, [isProposition]);
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, title: title },
        React.createElement("div", { className: "job-payment-modal-inner" },
            React.createElement(JobPaymentControl, { account: account, amountDue: amountDue, bacsDetails: bacsDetails, customer: customer, getStripePaymentIntent: getStripePaymentIntent, isDemo: isDemo, isGettingStripePaymentIntent: isGettingStripePaymentIntent, isProposition: isProposition, jobContactId: jobContactId, jobTotalAmountDue: jobTotalAmountDue, onPaymentComplete: onPaymentComplete, previewToken: previewToken, showStripePayment: showStripePayment, stripePaymentIntent: stripePaymentIntent }),
            account.paymentTerms && (React.createElement("p", { className: "payment-terms" }, account.paymentTerms)))));
};
export default JobPaymentModal;
