import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { AddPaymentMethodForm } from '../../components';
var StripePaymentControl = function (_a) {
    var amountDue = _a.amountDue, companyName = _a.companyName, customerName = _a.customerName, customerEmail = _a.customerEmail, onPayment = _a.onPayment, stripePaymentIntent = _a.stripePaymentIntent, stripePromise = _a.stripePromise, onComponentRenderChange = _a.onComponentRenderChange;
    return (React.createElement("div", { key: "pay-stripe", className: "payment-control stripe-payment-control" },
        React.createElement(Elements, { stripe: stripePromise },
            React.createElement(AddPaymentMethodForm, { amount: amountDue, clientSecret: stripePaymentIntent === null || stripePaymentIntent === void 0 ? void 0 : stripePaymentIntent.client_secret, companyName: companyName, customerEmail: customerEmail, customerName: customerName, onComplete: onPayment, onComponentRenderChange: onComponentRenderChange }))));
};
export default StripePaymentControl;
