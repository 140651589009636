import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { SignHerePrompt } from '../../components';
import './SignatureCapture.sass';
var SignatureCapture = function (_a) {
    var _b = _a.displaySignHerePrompt, displaySignHerePrompt = _b === void 0 ? false : _b, signatureCanvasReference = _a.signatureCanvasReference, setIsSignaturePresent = _a.setIsSignaturePresent;
    return (React.createElement("div", { className: "signature-capture " + (displaySignHerePrompt ? 'display-sign-here-prompt' : ''), style: { position: 'relative' } },
        React.createElement(SignatureCanvas, { ref: signatureCanvasReference, penColor: "black", canvasProps: {
                className: 'sigCanvas',
            }, clearOnResize: false, onEnd: function () { return setIsSignaturePresent(true); } }),
        React.createElement("div", { className: "clear-signature", onClick: function () {
                var _a;
                (_a = signatureCanvasReference.current) === null || _a === void 0 ? void 0 : _a.clear();
                setIsSignaturePresent(false);
            } }, "Clear"),
        React.createElement(SignHerePrompt, null)));
};
export default SignatureCapture;
