import React, { useRef, useState, useCallback } from 'react';
import { SignatureCaptureAndTerms } from '../../components';
import QuoteExpiredModal from '../quoteExpiredModel/QuoteExpiredModal';
import { Button, ButtonStyleVariant, ButtonColourVariant, } from '@payaca/components/button';
import agent from '../../agent';
import { useSmallViewListener } from '../../utils/customHooks';
import './JobSignatureCaptureControl.sass';
var JobSignatureCaptureControl = function (_a) {
    var _b = _a.displaySignHerePrompt, displaySignHerePrompt = _b === void 0 ? false : _b, _c = _a.isPaymentRequired, isPaymentRequired = _c === void 0 ? false : _c, isQuoteExpired = _a.isQuoteExpired, jobType = _a.jobType, onDecline = _a.onDecline, previewToken = _a.previewToken, showDeclineOption = _a.showDeclineOption, terms = _a.terms, onSignatureCaptureComplete = _a.onSignatureCaptureComplete;
    var controlReference = useRef(null);
    var signatureCanvasReference = useRef();
    var isSmallView = useSmallViewListener(controlReference, 350)[0];
    var _d = useState(false), isSignaturePresent = _d[0], setIsSignaturePresent = _d[1];
    var _e = useState(false), isActionProcessing = _e[0], setIsActionProcessing = _e[1];
    var _f = useState(false), showQuoteExpiredModal = _f[0], setShowQuoteExpiredModal = _f[1];
    var onAccept = useCallback(function () {
        var _a;
        if (showQuoteExpiredModal) {
            setShowQuoteExpiredModal(false);
        }
        var signatureImage = (_a = signatureCanvasReference === null || signatureCanvasReference === void 0 ? void 0 : signatureCanvasReference.current) === null || _a === void 0 ? void 0 : _a.toDataURL();
        setIsActionProcessing(true);
        agent.Auth.acceptQuote(previewToken, signatureImage).then(function (resp) {
            setIsActionProcessing(false);
            onSignatureCaptureComplete &&
                onSignatureCaptureComplete((resp === null || resp === void 0 ? void 0 : resp.error) || null);
        });
    }, [
        onSignatureCaptureComplete,
        previewToken,
        showQuoteExpiredModal,
        signatureCanvasReference,
    ]);
    var captureSignature = useCallback(function () {
        var _a;
        if (!isSignaturePresent || ((_a = signatureCanvasReference === null || signatureCanvasReference === void 0 ? void 0 : signatureCanvasReference.current) === null || _a === void 0 ? void 0 : _a.isEmpty())) {
            return;
        }
        else if (isQuoteExpired) {
            setShowQuoteExpiredModal(true);
        }
        else {
            onAccept();
        }
    }, [onAccept, isQuoteExpired, isSignaturePresent, signatureCanvasReference]);
    return (React.createElement("div", { className: "job-signature-capture-control" + (isSmallView ? ' small-view' : ''), ref: controlReference },
        React.createElement(SignatureCaptureAndTerms, { displaySignHerePrompt: displaySignHerePrompt, jobType: jobType, terms: terms, signatureCanvasReference: signatureCanvasReference, setIsSignaturePresent: setIsSignaturePresent }),
        isPaymentRequired && (React.createElement("div", { className: "deposit-message" },
            "A deposit payment is required for this ",
            jobType.toLowerCase(),
            ". You will be able to make payment after you have provided your signature.")),
        showDeclineOption && (React.createElement("div", { className: "decline-message" },
            "If you do not wish to proceed with this quote please",
            ' ',
            React.createElement("span", { className: "link", onClick: function () { return onDecline && onDecline(); } }, "click here"),
            ' ',
            "to decline.")),
        React.createElement(Button, { isDisabled: !isSignaturePresent || isActionProcessing, onClick: captureSignature, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY }, isActionProcessing ? 'Accepting...' : 'Sign to accept'),
        React.createElement(QuoteExpiredModal, { isOpen: showQuoteExpiredModal, onAcceptQuote: onAccept, onClose: function () { return setShowQuoteExpiredModal(false); } })));
};
export default JobSignatureCaptureControl;
