import React from 'react';
import { Button, ButtonStyleVariant } from '@payaca/components/button';
import { Modal } from '@payaca/components/modal';
import { TextareaField } from '@payaca/components';
import './DeclineJobModal.sass';
var DeclineJobModal = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, onSubmit = _a.onSubmit, isSubmitting = _a.isSubmitting;
    var _b = React.useState(''), declineReason = _b[0], setDeclineReason = _b[1];
    return (React.createElement(Modal, { isOpen: isOpen, onClose: onClose, title: "Please could you let us know your reason for declining?" },
        React.createElement("div", null,
            React.createElement(TextareaField, { name: "declineReason", value: declineReason, onChange: function (value) {
                    setDeclineReason(value.declineReason);
                } }),
            React.createElement("div", { className: "decline-actions" },
                React.createElement(Button, { isProcessing: isSubmitting && !!declineReason, onClick: function () { return !isSubmitting && onSubmit(declineReason); }, styleVariant: ButtonStyleVariant.OUTSIZE }, "Submit"),
                React.createElement(Button, { isProcessing: isSubmitting && !declineReason, onClick: function () { return !isSubmitting && onSubmit(); }, styleVariant: ButtonStyleVariant.ANCHOR }, "Skip")))));
};
export default DeclineJobModal;
