var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/**
 * JobOverviewPreview
 * - Used for job preview on mobile app
 */
import React, { Fragment, useEffect, useMemo, useState, } from 'react';
import { JobAttachments, JobOverviewBusinessDetails, JobOverviewFooter, LoaderOverlay, } from '@payaca/components';
import { JobOverviewCta } from '../../components';
import { JobOverviewHeader } from '@payaca/components/jobOverviewHeader';
import { JobOverview } from '@payaca/components/jobOverview';
import { CollapsiblePanel, CollapsiblePanelStyleVariant, } from '@payaca/components/collapsiblePanel';
import { getMinimumMonthlyCost } from '@payaca/helpers/jobFinanceHelper';
import { getUrlSearchParam } from '@payaca/helpers/urlHelper';
import { isQuoteOrEstimate } from '@payaca/helpers/jobStatusHelper';
import { hasRejectedFinanceApplication, hasSubmittedFinanceApplication, } from '@payaca/helpers/jobFinanceApplicationHelper';
import { shouldShowFinanceOptionsToCustomer } from '@payaca/helpers/jobHelperV2';
import agent from '../../agent';
import './JobOverviewPreviewPage.sass';
var JobOverviewPreviewPage = function () {
    var previewToken = getUrlSearchParam(window.location.search, 'previewToken');
    var _a = useState(), accountAccessInformation = _a[0], setAccountAccessInformation = _a[1];
    var _b = useState(), job = _b[0], setJob = _b[1];
    var _c = useState(), customer = _c[0], setCustomer = _c[1];
    var _d = useState(), jobLineItems = _d[0], setJobLineItems = _d[1];
    var _e = useState(), jobLineItemAttachments = _e[0], setJobLineItemAttachments = _e[1];
    var _f = useState(), jobLineItemGroups = _f[0], setJobLineItemGroups = _f[1];
    var _g = useState(), jobFinanceInformation = _g[0], setJobFinanceInformation = _g[1];
    var _h = useState(), satisfactionNoteSignatureImageData = _h[0], setSatisfactionNoteSignatureImageData = _h[1];
    var _j = useState(), account = _j[0], setAccount = _j[1];
    var _k = useState(), acceptedSignatureImageData = _k[0], setAcceptedSignatureImageData = _k[1];
    var _l = useState(), jobPayments = _l[0], setJobPayments = _l[1];
    var _m = useState(), error = _m[0], setError = _m[1];
    var _o = useState(false), isLoading = _o[0], setIsLoading = _o[1];
    var _p = useState([]), financePlanConfigs = _p[0], setFinancePlanConfigs = _p[1];
    var _q = useState(), businessAttachments = _q[0], setBusinessAttachments = _q[1];
    var _r = useState(), jobAttachments = _r[0], setJobAttachments = _r[1];
    useEffect(function () {
        setIsLoading(true);
        agent.Auth.getJobOvervewInformation(previewToken)
            .then(function (response) {
            if (response) {
                setAccount(response.account);
                setAccountAccessInformation(response.accountAccessInformation);
                setCustomer(response.customer);
                setJob(response.job);
                setJobLineItems(response.jobLineItems);
                setJobLineItemAttachments(response.jobLineItemAttachments);
                setJobLineItemGroups(response.jobLineItemGroups);
                setJobAttachments(response.jobAttachments);
                setAcceptedSignatureImageData(response.acceptedSignatureImageData);
                setJobFinanceInformation(response.jobFinanceInformation);
                setSatisfactionNoteSignatureImageData(response.satisfactionNoteSignatureImageData);
                setJobPayments(response.jobPayments);
                setFinancePlanConfigs(response.financePlans);
                setBusinessAttachments(response.businessAttachments);
            }
            else {
                setError(new Error());
            }
        })
            .catch(function (error) {
            setError(error);
        })
            .finally(function () {
            setIsLoading(false);
        });
    }, [previewToken]);
    var isZeroPercentFinanceEnabled = useMemo(function () {
        // enabled on job, account is approved and subscription allows
        var jobIsQuoteOrEstimate = job ? isQuoteOrEstimate(job.status) : false;
        var jobHasZeroPercentEnabled = !!(job === null || job === void 0 ? void 0 : job.showBespokeFinancePlans);
        var accountIsZeroPercentApproved = !!(account && account.integrations.zeroPercentFinance.isApproved);
        var accountHasSubscriptionFeature = !!(accountAccessInformation &&
            accountAccessInformation.features.hasZeroPercentFinance);
        return (jobIsQuoteOrEstimate &&
            jobHasZeroPercentEnabled &&
            accountIsZeroPercentApproved &&
            accountHasSubscriptionFeature);
    }, [account, accountAccessInformation, job]);
    var minimumMonthlyPayment = useMemo(function () {
        if (job && (financePlanConfigs === null || financePlanConfigs === void 0 ? void 0 : financePlanConfigs.length)) {
            var minimumMonthlyCosts = financePlanConfigs
                .map(function (financePlanConfig) {
                return getMinimumMonthlyCost(job.total, financePlanConfig.maxDeposit, financePlanConfig.minAmount, financePlanConfig.interestRate, financePlanConfig.agreementDuration);
            })
                .sort(function (a, b) { return a - b; });
            // lowest monthly cost from finance plans
            return "\u00A3" + Math.ceil(minimumMonthlyCosts[0] / 100);
        }
        return null;
    }, [financePlanConfigs, job]);
    var interestRate = useMemo(function () {
        var _a;
        if (!(financePlanConfigs === null || financePlanConfigs === void 0 ? void 0 : financePlanConfigs.length)) {
            return 0;
        }
        var percentageDecimal = Number((_a = financePlanConfigs[0]) === null || _a === void 0 ? void 0 : _a.interestRate) || 0;
        return 100 * percentageDecimal;
    }, [financePlanConfigs]);
    var financePaymentTitle = useMemo(function () {
        return "" + (financePlanConfigs.length > 1 ? 'Finance' : interestRate + "% finance") + (minimumMonthlyPayment ? " from " + minimumMonthlyPayment + " per month" : '');
    }, [financePlanConfigs, minimumMonthlyPayment]);
    var standardAndFinancePaymentMethodsElement = useMemo(function () {
        return (React.createElement("div", { className: "standard-and-finance-panels-wrapper" },
            React.createElement(CollapsiblePanel, { title: "Standard payment", isOpen: false, onOpen: function () { return null; }, onClose: function () { return null; }, styleVariant: CollapsiblePanelStyleVariant.MINIMAL }),
            React.createElement(CollapsiblePanel, { title: financePaymentTitle, isOpen: false, onOpen: function () { return null; }, onClose: function () { return null; }, styleVariant: CollapsiblePanelStyleVariant.MINIMAL })));
    }, [financePaymentTitle]);
    // zero percent enabled, havent submitted application or submitted and declined
    var showFinanceApplyAndStandardSignatureOption = useMemo(function () {
        return job &&
            isZeroPercentFinanceEnabled &&
            (!hasSubmittedFinanceApplication(jobFinanceInformation) ||
                (hasSubmittedFinanceApplication(jobFinanceInformation) &&
                    hasRejectedFinanceApplication(jobFinanceInformation) &&
                    !job.declinedAt));
    }, [job, jobFinanceInformation, isZeroPercentFinanceEnabled]);
    var showFinanceOptions = useMemo(function () {
        if (!job)
            return false;
        return shouldShowFinanceOptionsToCustomer(job, jobPayments || [], jobFinanceInformation);
    }, [job, jobPayments, jobFinanceInformation]);
    var financeOptionsOverlayTriggerElement = useMemo(function () {
        if (!showFinanceOptions)
            return null;
        return (React.createElement("div", { className: "finance-options-overlay-trigger-container" },
            React.createElement(JobOverviewCta, { showPaymentMethodIcons: false }, "Finance available")));
    }, [showFinanceOptions]);
    var callToAction = useMemo(function () {
        if (showFinanceApplyAndStandardSignatureOption) {
            return standardAndFinancePaymentMethodsElement;
        }
        else if (showFinanceOptions) {
            return financeOptionsOverlayTriggerElement;
        }
        return null;
    }, [
        standardAndFinancePaymentMethodsElement,
        showFinanceApplyAndStandardSignatureOption,
        showFinanceOptions,
        financeOptionsOverlayTriggerElement,
    ]);
    var jobAttachmentsElement = useMemo(function () {
        var attachments = [];
        if (jobAttachments === null || jobAttachments === void 0 ? void 0 : jobAttachments.length) {
            attachments = __spreadArrays(jobAttachments);
        }
        if (businessAttachments && businessAttachments.length) {
            attachments = __spreadArrays(attachments, businessAttachments);
        }
        if (attachments.length) {
            return (React.createElement(JobAttachments, { jobAttachments: attachments, isDownloadDisabled: true }));
        }
    }, [account, businessAttachments, job, jobAttachments]);
    return isLoading || !customer ? (React.createElement(LoaderOverlay, null)) : (React.createElement("div", { className: "job-overview-preview-page", id: "job-overview-preview-page" },
        job && account && (React.createElement(Fragment, null,
            React.createElement("div", { className: "job-overview-outer" },
                React.createElement(JobOverviewHeader, { account: account, customer: customer, job: job, jobFinanceInformation: jobFinanceInformation }),
                React.createElement(JobOverview, { acceptedSignatureImageData: acceptedSignatureImageData, customer: customer, jobPayments: jobPayments || [], account: account, callToAction: callToAction || undefined, isEditable: false, job: job, jobLineItems: jobLineItems || [], jobLineItemAttachments: jobLineItemAttachments || [], jobLineItemGroups: jobLineItemGroups || [], jobFinanceInformation: jobFinanceInformation, onUpdate: function () { return null; }, satisfactionNoteSignatureImageData: satisfactionNoteSignatureImageData }),
                React.createElement(JobOverviewBusinessDetails, { account: account, jobFinanceInformation: jobFinanceInformation, job: job }),
                jobAttachmentsElement),
            job && account && React.createElement(JobOverviewFooter, { account: account }))),
        error && React.createElement("p", null, "Something went wrong.")));
};
export default JobOverviewPreviewPage;
