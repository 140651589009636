import React from 'react';
import TooltipUI from '@material-ui/core/Tooltip';
import './Tooltip.sass';
import { InfoCircle } from 'react-iconly';
var Tooltip = function (_a) {
    var text = _a.text, _b = _a.placement, placement = _b === void 0 ? 'top' : _b, children = _a.children;
    return (React.createElement(TooltipUI, { title: text, arrow: true, placement: placement, className: "tooltip-wrapper" },
        React.createElement("span", null, children || (React.createElement("span", { className: "info-circle-container tooltip-icon" },
            React.createElement(InfoCircle, { size: 16, set: "light" }))))));
};
export default Tooltip;
