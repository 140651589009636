import React, { useCallback, useEffect } from "react";
import Slider from "../slider/Slider";
var PercentageAmountSlider = function (_a) {
    var amountTotal = _a.amountTotal, description = _a.description, inputValue = _a.inputValue, isDisabled = _a.isDisabled, maxAmount = _a.maxAmount, minPercentage = _a.minPercentage, maxPercentage = _a.maxPercentage, minAmount = _a.minAmount, onInputChange = _a.onInputChange, onSliderChange = _a.onSliderChange, sliderValue = _a.sliderValue;
    useEffect(function () {
        // set initial value if provided
        if (sliderValue) {
            onInputChange(calculateInputFromSlider(sliderValue));
        }
        else if (inputValue) {
            onSliderChange(calculateSliderFromInput(inputValue));
        }
        else {
            //  set to minimum values
            onSliderChange(minPercentage);
            onInputChange(calculateInputFromSlider(minPercentage));
        }
    }, []);
    useEffect(function () {
        // if job total changes - update the slider and input values
        onSliderChange(minPercentage);
        onInputChange(calculateInputFromSlider(minPercentage));
    }, [amountTotal]);
    var calculateInputFromSlider = useCallback(function (sliderVal) { return Math.round((sliderVal / 100) * amountTotal); }, [amountTotal]);
    var calculateSliderFromInput = useCallback(function (inputVal) { return (inputVal / amountTotal) * 100; }, [amountTotal]);
    var onSliderValueChange = function (newSliderValue) {
        // calculate new deposit value
        var newInputValue = calculateInputFromSlider(newSliderValue);
        // update input and slider with new value
        onInputChange(newInputValue);
        onSliderChange(newSliderValue);
    };
    var onInputValueChange = function (newInputValue) {
        // calculate new slider value
        var newSliderValue = calculateSliderFromInput(newInputValue);
        // update input and slider with new value
        onSliderChange(newSliderValue);
        onInputChange(newInputValue);
    };
    var onBlur = function () {
        // get rounded value
        var formattedInput = Math.round(inputValue);
        var inputMinLimitDiff = formattedInput - minAmount;
        var inputMaxLimitDiff = maxAmount - formattedInput;
        if (inputMinLimitDiff < 0 || inputMaxLimitDiff < 0) {
            // amount out of limits, default to a limit value
            onInputChange(inputMinLimitDiff < inputMaxLimitDiff ? minAmount : maxAmount);
            onSliderChange(inputMinLimitDiff < inputMaxLimitDiff ? minPercentage : maxPercentage);
        }
        else {
            // Update input to rounded value
            onInputChange(formattedInput);
        }
    };
    var sliderLabelFormat = function (x) { return Math.round(x * 100) / 100 + "%"; };
    return (React.createElement(Slider, { description: description, inputValue: inputValue, inputLabel: "\u00A3", isDisabled: isDisabled, maxSliderValue: maxPercentage, minSliderValue: minPercentage, onInputBlur: function () { return onBlur(); }, onInputChange: function (newValue) { return onInputValueChange(newValue); }, onSliderChange: function (newValue) { return onSliderValueChange(newValue); }, sliderLabelFormat: sliderLabelFormat, sliderStep: 1, sliderValue: sliderValue }));
};
export default PercentageAmountSlider;
