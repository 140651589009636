import React, { useCallback, useRef, useMemo, useEffect, useState, } from 'react';
import './CollapsiblePanel.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, } from '@fortawesome/free-solid-svg-icons';
export var CollapsiblePanelStyleVariant;
(function (CollapsiblePanelStyleVariant) {
    CollapsiblePanelStyleVariant["STANDARD"] = "standard";
    CollapsiblePanelStyleVariant["OUTSIZE"] = "outsize";
    CollapsiblePanelStyleVariant["MINIMAL"] = "minimal";
    CollapsiblePanelStyleVariant["UNSTYLED"] = "unstyled";
})(CollapsiblePanelStyleVariant || (CollapsiblePanelStyleVariant = {}));
var CollapsiblePanel = function (_a) {
    var _b = _a.openControlIcon, openControlIcon = _b === void 0 ? faChevronRight : _b, _c = _a.closeControlIcon, closeControlIcon = _c === void 0 ? faChevronDown : _c, _d = _a.delayMs, delayMs = _d === void 0 ? 0 : _d, title = _a.title, children = _a.children, _e = _a.isDisabled, isDisabled = _e === void 0 ? false : _e, isOpen = _a.isOpen, _f = _a.showControlIcons, showControlIcons = _f === void 0 ? true : _f, _g = _a.transitionDurationMs, transitionDurationMs = _g === void 0 ? 300 : _g, _h = _a.shouldRenderHeader, shouldRenderHeader = _h === void 0 ? true : _h, onOpen = _a.onOpen, onClose = _a.onClose, _j = _a.styleVariant, styleVariant = _j === void 0 ? CollapsiblePanelStyleVariant.STANDARD : _j, watchSize = _a.watchSize;
    var bodyReference = useRef(null);
    var _k = useState(0), bodyContainerHeight = _k[0], setBodyContainerheight = _k[1];
    var getBodyContainerHeight = useCallback(function () {
        var _a;
        if (!isOpen) {
            return 0;
        }
        return ((_a = bodyReference === null || bodyReference === void 0 ? void 0 : bodyReference.current) === null || _a === void 0 ? void 0 : _a.clientHeight) || 0;
    }, [isOpen, bodyReference]);
    var transitionDuration = useMemo(function () { return transitionDurationMs + "ms"; }, [transitionDurationMs]);
    var onHeaderClick = useCallback(function () {
        if (isDisabled) {
            return;
        }
        isOpen ? onClose && onClose() : onOpen && onOpen();
    }, [isDisabled, isOpen, onClose, onOpen]);
    useEffect(function () {
        setBodyContainerheight(getBodyContainerHeight());
    }, []);
    useEffect(function () {
        setTimeout(function () {
            setBodyContainerheight(getBodyContainerHeight());
        }, delayMs);
    }, [isOpen, bodyReference, children, delayMs, watchSize]);
    return (React.createElement("div", { className: "collapsible-panel" + (isOpen ? ' open' : '') + (isDisabled ? ' disabled' : '') + " collapsible-panel-style-" + styleVariant, style: { transitionDuration: transitionDuration } },
        shouldRenderHeader && (React.createElement("div", { className: "collapsible-panel-header", onClick: onHeaderClick, style: { transitionDuration: transitionDuration } },
            React.createElement("div", { className: "title" }, title),
            showControlIcons && (React.createElement("span", { className: "control-icon-container" },
                React.createElement(FontAwesomeIcon, { icon: openControlIcon, className: "open-control-icon" }),
                React.createElement(FontAwesomeIcon, { icon: closeControlIcon, className: "close-control-icon" }))))),
        React.createElement("div", { className: "collapsible-panel-body-container", style: {
                height: bodyContainerHeight,
                transitionDuration: transitionDuration,
            } },
            React.createElement("div", { className: "collapsible-panel-body", ref: bodyReference }, children))));
};
export default CollapsiblePanel;
