import { useEffect, useState } from 'react';
export function useSmallViewListener(elementReference, smallViewSize) {
    var _a = useState(false), isSmallView = _a[0], setIsSmallView = _a[1];
    useEffect(function () {
        var calculateIsSmallView = function () {
            var _a;
            elementReference.current &&
                ((_a = elementReference.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) < smallViewSize
                ? setIsSmallView(true)
                : setIsSmallView(false);
        };
        calculateIsSmallView();
        window.addEventListener('resize', calculateIsSmallView);
        return function () {
            window.removeEventListener('resize', calculateIsSmallView);
        };
    }, [elementReference, smallViewSize]);
    return [isSmallView];
}
