import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PercentageAmountSlider from '../percentageAmountSlider/PercentageAmountSlider';
import LabelValuePair from '../labelValuePair/LabelValuePair';
import Button, { ButtonColourVariant, ButtonStyleVariant, } from '../button/Button';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getMaximumDepositPercentage, getMinimumDepositPercentage, getMaximumDepositAmountFromMaxDepositPercentage, getMinimumDepositAmountFromMinDepositPercentage, getTotalCreditAndMonthlyCost, } from '@payaca/helpers/jobFinanceHelper';
import './JobOverviewFinanceBreakdown.sass';
var JobOverviewFinanceBreakdown = function (_a) {
    var amountTotal = _a.amountTotal, companyName = _a.companyName, deferralPeriod = _a.deferralPeriod, financeMonths = _a.financeMonths, inputValue = _a.inputValue, interestPercentage = _a.interestPercentage, isApplying = _a.isApplying, isDisabled = _a.isDisabled, maxFinanceAmount = _a.maxFinanceAmount, maxDepositPercentage = _a.maxDepositPercentage, minFinanceAmount = _a.minFinanceAmount, minDepositPercentage = _a.minDepositPercentage, onApply = _a.onApply, onInputChange = _a.onInputChange, onSliderChange = _a.onSliderChange, sliderValue = _a.sliderValue;
    var maxDepositPercentageValue = useMemo(function () {
        return getMaximumDepositPercentage(amountTotal, minFinanceAmount, maxDepositPercentage);
    }, [amountTotal, minFinanceAmount, maxDepositPercentage]);
    var minDepositPercentageValue = useMemo(function () {
        return getMinimumDepositPercentage(amountTotal, maxFinanceAmount, minDepositPercentage);
    }, [amountTotal, maxFinanceAmount, minDepositPercentage]);
    var maxDepositAmountValue = useMemo(function () {
        return getMaximumDepositAmountFromMaxDepositPercentage(amountTotal, maxDepositPercentageValue);
    }, [amountTotal, maxDepositPercentageValue]);
    var minDepositAmountValue = useMemo(function () {
        return getMinimumDepositAmountFromMinDepositPercentage(amountTotal, minDepositPercentageValue);
    }, [amountTotal, minDepositPercentageValue]);
    var totalCreditAndMonthlyCost = useMemo(function () {
        return getTotalCreditAndMonthlyCost(amountTotal, inputValue, interestPercentage, financeMonths);
    }, [amountTotal, financeMonths, inputValue, interestPercentage]);
    var description = useMemo(function () {
        var descriptionItems = [
            "This deposit is payable " + (companyName ? "to " + companyName + " " : '') + "on\n      completion of the application process and prior to the quoted work\n      commencing.",
            "Choosing a higher deposit amount may increase the chance of your\n      application being approved.",
        ];
        if (deferralPeriod) {
            descriptionItems.unshift("Pay in full within " + deferralPeriod + " months and pay no interest.", "Any remaining amount after " + deferralPeriod + " months can be repaid over the following " + financeMonths + " months at " + interestPercentage + "% APR.");
        }
        return (React.createElement("ul", null, descriptionItems.map(function (descriptionItem) {
            return (React.createElement("li", null,
                React.createElement("span", { className: "icon-container" },
                    React.createElement(FontAwesomeIcon, { icon: faChevronRight })),
                React.createElement("span", null, descriptionItem)));
        })));
    }, [companyName, deferralPeriod, financeMonths, interestPercentage]);
    return (
    // TODO: make finance breakdown and title more dynamic for other plans
    React.createElement("div", { className: "job-overview-finance-breakdown" },
        description,
        React.createElement(PercentageAmountSlider, { amountTotal: amountTotal, description: "Choose your deposit", inputValue: inputValue, isDisabled: isDisabled, maxAmount: maxDepositAmountValue, maxPercentage: maxDepositPercentageValue, minAmount: minDepositAmountValue, minPercentage: minDepositPercentageValue, onInputChange: function (inputVal) { return onInputChange(inputVal); }, onSliderChange: function (sliderVal) { return onSliderChange(sliderVal); }, sliderValue: sliderValue }),
        !!deferralPeriod && (React.createElement("span", { className: "after-deferral-description" },
            "After ",
            deferralPeriod,
            " months interest free period")),
        React.createElement("div", { className: "finance-breakdown" },
            React.createElement(LabelValuePair, { label: "Term", value: financeMonths + " months" }),
            React.createElement(LabelValuePair, { label: "Interest", value: interestPercentage + "%" }),
            React.createElement(LabelValuePair, { label: "Total credit", value: currencyPrice(totalCreditAndMonthlyCost.totalCredit) }),
            React.createElement(LabelValuePair, { label: "Cost per month", value: currencyPrice(totalCreditAndMonthlyCost.monthlyCost) })),
        React.createElement("div", { className: "button-container" },
            React.createElement(Button, { onClick: onApply, isProcessing: isApplying, isDisabled: isDisabled, styleVariant: ButtonStyleVariant.OUTSIZE, colourVariant: ButtonColourVariant.PRIMARY }, "Apply now"))));
};
export default JobOverviewFinanceBreakdown;
