import React from "react";
import "./PaymentMethodIcons.css";
var PaymentMethodIcons = function () {
    return (React.createElement("div", { className: "payment-method-icon-container" },
        React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/visa.svg", alt: "visa icon" }),
        React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/mastercard.svg", alt: "mastercard icon" }),
        React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/americanexpress.svg", alt: "americanexpress icon" }),
        React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/applepay.svg", alt: "applepay icon" }),
        React.createElement("img", { src: "https://storage.googleapis.com/payaca-prod-assets/androidpay.svg", alt: "androidpay icon" })));
};
export default PaymentMethodIcons;
