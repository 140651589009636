import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
var FinanceOptionsOverlayActionBar = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement("div", { className: "finance-options-overlay-action-bar flex-cntainer flex-center" },
        React.createElement("span", { className: "action-bar-title-and-description" },
            React.createElement("span", { className: "action-bar-title" }, "Finance options available"),
            React.createElement("span", { className: "action-bar-description" })),
        React.createElement("div", { className: "dismiss-action", onClick: function (e) {
                e.stopPropagation();
                onClose();
            } },
            React.createElement(FontAwesomeIcon, { icon: faTimesCircle }))));
};
export default FinanceOptionsOverlayActionBar;
