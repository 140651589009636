import React, { useCallback, useEffect, useMemo, useState, } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import agent from '../../agent';
import { PaymentMethodType } from '../../types/paymentTypes';
import { StripePaymentControl, BacsPaymentControl, AccountContactDetails, } from '../../components';
import { CalloutPanel, CollapsiblePanel, LoaderOverlay, MiniLoader, PaymentMethodIcons, } from '@payaca/components';
import './JobPaymentControl.sass';
var STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || '';
var JobPaymentControl = function (_a) {
    var account = _a.account, amountDue = _a.amountDue, bacsDetails = _a.bacsDetails, customer = _a.customer, getStripePaymentIntent = _a.getStripePaymentIntent, _b = _a.isDemo, isDemo = _b === void 0 ? false : _b, isGettingStripePaymentIntent = _a.isGettingStripePaymentIntent, isProposition = _a.isProposition, jobContactId = _a.jobContactId, jobTotalAmountDue = _a.jobTotalAmountDue, onPaymentComplete = _a.onPaymentComplete, previewToken = _a.previewToken, showStripePayment = _a.showStripePayment, stripePaymentIntent = _a.stripePaymentIntent;
    var _c = useState(null), selectedPaymentMethodType = _c[0], setSelectedPaymentMethodType = _c[1];
    var _d = useState(false), isPaymentProcessing = _d[0], setIsPaymentProcessing = _d[1];
    var _e = useState(amountDue), bacsAmount = _e[0], setBacsAmount = _e[1];
    var _f = useState(), cardPaymentWatchSizeTrigger = _f[0], setCardPaymentWatchSizeTrigger = _f[1];
    var stripePromise = useMemo(function () {
        if (stripePaymentIntent) {
            return loadStripe(STRIPE_KEY, {
                stripeAccount: stripePaymentIntent.stripe_connect_user_id,
            });
        }
        return null;
    }, [stripePaymentIntent]);
    var formattedAmountDue = useMemo(function () {
        return "\u00A3" + ((amountDue || 0) / 100).toFixed(2);
    }, [amountDue]);
    useEffect(function () {
        // default bacsAmount to full deposit
        setBacsAmount(amountDue);
    }, []);
    var isBacsPaymentOptionAvailable = useMemo(function () {
        if (isDemo)
            return false;
        return !!(amountDue &&
            amountDue > 0 && (bacsDetails === null || bacsDetails === void 0 ? void 0 : bacsDetails.accountName) && (bacsDetails === null || bacsDetails === void 0 ? void 0 : bacsDetails.accountNumber) && (bacsDetails === null || bacsDetails === void 0 ? void 0 : bacsDetails.sortCode));
    }, [amountDue, bacsDetails]);
    var isStripePaymentOptionAvailable = useMemo(function () {
        return !isDemo && !!(amountDue && amountDue > 0 && showStripePayment);
    }, [amountDue, isDemo, showStripePayment]);
    var isContactBusinessToPayOptionAvailable = useMemo(function () {
        return (!isDemo &&
            !isStripePaymentOptionAvailable &&
            !isBacsPaymentOptionAvailable);
    }, [isDemo, isStripePaymentOptionAvailable, isBacsPaymentOptionAvailable]);
    var isSinglePaymentOptionAvailable = useMemo(function () {
        var paymentMethodCount = 0;
        if (isBacsPaymentOptionAvailable)
            paymentMethodCount += 1;
        if (isStripePaymentOptionAvailable)
            paymentMethodCount += 1;
        if (isContactBusinessToPayOptionAvailable)
            paymentMethodCount += 1;
        return paymentMethodCount === 1;
    }, [
        isBacsPaymentOptionAvailable,
        isStripePaymentOptionAvailable,
        isContactBusinessToPayOptionAvailable,
    ]);
    var onStripePayment = useCallback(function () {
        if (stripePaymentIntent === null || stripePaymentIntent === void 0 ? void 0 : stripePaymentIntent.id) {
            setIsPaymentProcessing(true);
            var markStripePaymentAttemptedPromise = isProposition
                ? agent.Auth.markQuoteDepositStripePaymentAttempted(previewToken, stripePaymentIntent.id)
                : agent.Auth.markInvoiceStripePaymentAttempted(previewToken, stripePaymentIntent.id);
            Promise.race([
                markStripePaymentAttemptedPromise,
                new Promise(function (resolve, reject) {
                    setTimeout(resolve, 5000);
                }),
            ]).then(function (value) {
                setIsPaymentProcessing(false);
                onPaymentComplete({
                    title: 'Thank you for your payment',
                    body: 'You will receive an email receipt shortly.',
                });
            });
        }
    }, [onPaymentComplete, stripePaymentIntent]);
    var onBacsPaymentCallback = useCallback(function (resp) {
        setIsPaymentProcessing(false);
        onPaymentComplete(resp.error
            ? { title: "Sorry, there was an error", body: "Please try again." }
            : {
                title: "Thank you for confirming",
                body: "We've let " + (account === null || account === void 0 ? void 0 : account.companyName) + " know you'll be making the transfer.",
            });
    }, [onPaymentComplete, account]);
    var onBacsPayment = useCallback(function () {
        setIsPaymentProcessing(true);
        if (isProposition) {
            agent.Auth.recordQuoteDepositBACSPayment(previewToken, bacsAmount).then(onBacsPaymentCallback);
        }
        else {
            agent.Auth.recordInvoiceBACSPayment(previewToken, bacsAmount).then(onBacsPaymentCallback);
        }
    }, [bacsAmount, onBacsPaymentCallback, isProposition, previewToken]);
    var bacsPaymentElement = useMemo(function () {
        return (React.createElement("div", null,
            React.createElement("p", null, "Pay by making a bank transfer to the following details:"),
            React.createElement(BacsPaymentControl, { bacsDetails: bacsDetails, onPayment: onBacsPayment, bacsAmount: bacsAmount, onBacsAmountChange: function (newAmount) { return setBacsAmount(newAmount); }, amountDue: amountDue, jobTotalAmountDue: jobTotalAmountDue })));
    }, [amountDue, bacsAmount, bacsDetails, onBacsPayment, formattedAmountDue]);
    var contactBusinessElement = useMemo(function () {
        return (React.createElement("div", null,
            React.createElement("p", null,
                "Pay ",
                React.createElement("strong", null, formattedAmountDue),
                " by contacting the business directly:"),
            React.createElement(AccountContactDetails, { account: account })));
    }, [formattedAmountDue, account]);
    var stripePaymentElement = useMemo(function () {
        var jobContact = getJobContactFromCustomer(customer, jobContactId);
        return (React.createElement("div", null,
            React.createElement("p", null,
                "Pay ",
                React.createElement("strong", null, formattedAmountDue),
                " by card"),
            React.createElement(StripePaymentControl, { amountDue: amountDue, stripePaymentIntent: stripePaymentIntent, stripePromise: stripePromise, customerName: jobContact === null || jobContact === void 0 ? void 0 : jobContact.name, customerEmail: jobContact === null || jobContact === void 0 ? void 0 : jobContact.emailAddress, companyName: account.companyName, onPayment: onStripePayment, onComponentRenderChange: function () {
                    return setCardPaymentWatchSizeTrigger(new Date());
                } })));
    }, [
        formattedAmountDue,
        amountDue,
        stripePaymentIntent,
        stripePromise,
        customer,
        account,
        onStripePayment,
    ]);
    useEffect(function () {
        if (isStripePaymentOptionAvailable && isBacsPaymentOptionAvailable
            ? selectedPaymentMethodType === PaymentMethodType.STRIPE_PAYMENT
            : true && showStripePayment) {
            // get stripe payment intent
            getStripePaymentIntent();
        }
    }, [
        isStripePaymentOptionAvailable,
        isBacsPaymentOptionAvailable,
        selectedPaymentMethodType,
        showStripePayment,
    ]);
    return (React.createElement("div", { className: "job-payment-control" },
        React.createElement("div", { className: "payment-summary" },
            "Complete your ",
            isProposition ? 'deposit' : 'invoice',
            " payment of",
            ' ',
            React.createElement("strong", null, formattedAmountDue)),
        isPaymentProcessing && (React.createElement("div", null,
            React.createElement(LoaderOverlay, null))),
        React.createElement("div", { className: "payment-method-selection-container" + (isSinglePaymentOptionAvailable ? ' single-payment-method' : '') },
            isStripePaymentOptionAvailable && (React.createElement(CollapsiblePanel, { title: React.createElement("span", { className: "card-payment-panel-header flex-container flex-center" },
                    React.createElement("span", null, "Card payment"),
                    React.createElement(PaymentMethodIcons, null)), showControlIcons: isBacsPaymentOptionAvailable, isOpen: isBacsPaymentOptionAvailable
                    ? selectedPaymentMethodType === PaymentMethodType.STRIPE_PAYMENT
                    : true, onOpen: function () {
                    return setSelectedPaymentMethodType(PaymentMethodType.STRIPE_PAYMENT);
                }, onClose: function () { return setSelectedPaymentMethodType(null); }, watchSize: cardPaymentWatchSizeTrigger }, !isGettingStripePaymentIntent && stripePromise ? (stripePaymentElement) : (React.createElement("div", { className: "stripe-loading-wrapper" },
                React.createElement(MiniLoader, null))))),
            isBacsPaymentOptionAvailable && (React.createElement(CollapsiblePanel, { title: "BACS Transfer", isOpen: isStripePaymentOptionAvailable
                    ? selectedPaymentMethodType === PaymentMethodType.BACS_TRANSFER
                    : true, showControlIcons: isStripePaymentOptionAvailable, onOpen: function () {
                    return setSelectedPaymentMethodType(PaymentMethodType.BACS_TRANSFER);
                }, onClose: function () { return setSelectedPaymentMethodType(null); } }, bacsPaymentElement)),
            isContactBusinessToPayOptionAvailable && (React.createElement(CollapsiblePanel, { title: "Contact business to pay", isOpen: true, showControlIcons: false, onOpen: function () { return null; }, onClose: function () { return null; } }, contactBusinessElement)),
            isDemo && (React.createElement(CalloutPanel, null,
                "Payment methods are unavailable as this is a ",
                React.createElement("strong", null, "DEMO"),
                ' ',
                "quote - no payment is required.")))));
};
export default JobPaymentControl;
