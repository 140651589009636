import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './SignHerePrompt.sass';
var SignHerePrompt = function () {
    return (React.createElement("div", { className: "sign-here-prompt" },
        React.createElement("span", null, "Sign here"),
        React.createElement("span", { className: "animated-icon-container" },
            React.createElement(FontAwesomeIcon, { icon: faArrowRight }))));
};
export default SignHerePrompt;
