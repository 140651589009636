import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducer from './reducer';
import { sagaCreator as accountSagaCreator } from '@payaca/store/account';
import { sagaCreator as authSagaCreator } from '@payaca/store/auth';

import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import SagaMiddleware from 'redux-saga';

export const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const appRouterMiddleware = routerMiddleware(history);

const sagaMiddleware = new SagaMiddleware();

const getMiddleware = () => {
  const defaultMiddleware = [appRouterMiddleware, sagaMiddleware];
  if (process.env.NODE_ENV !== 'production' && process.env.LOGGING) {
    defaultMiddleware.push(createLogger());
  }
  return applyMiddleware.apply(null, defaultMiddleware);
};

const authSaga = authSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => null,
  getRefreshToken: async () => null,
  storeAuthTokens: async (authTokens) => null,
});

const accountSaga = accountSagaCreator({
  apiBaseurl: process.env.REACT_APP_API_HOST,
  getAuthHeader: async () => ``,
  isNativeApp: false,
});

export const store = createStore(reducer, composeWithDevTools(getMiddleware()));

sagaMiddleware.run(authSaga);
sagaMiddleware.run(accountSaga);
