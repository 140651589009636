import React, { useMemo } from 'react';
import './AccountContactDetails.sass';
var AccountContactDetails = function (_a) {
    var account = _a.account;
    var emailAddress = useMemo(function () {
        return account.email || account.fallbackEmail;
    }, [account]);
    return (React.createElement("div", { className: "account-contact-details" },
        React.createElement("p", null,
            React.createElement("span", { className: "company-name" }, account.companyName)),
        emailAddress && (React.createElement("p", { className: "flex-container" },
            React.createElement("span", { className: "label" }, "Email:"),
            React.createElement("a", { href: "mailto:" + emailAddress }, emailAddress))),
        account.contactNumber && (React.createElement("p", { className: "flex-container" },
            React.createElement("span", { className: "label" }, "Phone:"),
            React.createElement("a", { href: "tel:" + account.contactNumber }, account.contactNumber))),
        !emailAddress && !account.contactNumber && (React.createElement("p", null, "No contact details have been provided"))));
};
export default AccountContactDetails;
