import React, { Component } from 'react';
import { Switch, Route, Router } from 'react-router';
import { createGenerateClassName } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import JssProvider from 'react-jss/lib/JssProvider';

import { history } from '../store';
import agent from '../agent';
import JobOverviewPage from '../pages/JobOverviewPage/JobOverviewPage';
import JobOverviewPreviewPage from '../pages/JobOverviewPreviewPage/JobOverviewPreviewPage';
import Job from '../pages/Job/Job';
import SubsidisedFinanceInformationOverviewPage from '../pages/SubsidisedFinanceInformationOverviewPage/SubsidisedFinanceInformationOverviewPage';
import DemoQuoteLandingPage from '../pages/DemoQuoteLandingPage/DemoQuoteLandingPage';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'c',
});

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    type: 'light',
    primary: {
      main: '#ababab',
      contrastText: '#fff',
    },
    secondary: {
      main: '#14a99d',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      containedSecondary: {
        color: '#fff',
      },
    },
  },
});

class App extends Component {
  UNSAFE_componentWillMount() {
    if (localStorage.getItem('jwt')) {
      agent.setToken(localStorage.getItem('jwt'));
    }
  }
  render() {
    return (
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <JssProvider generateClassName={generateClassName}>
            <Switch>
              <Route exact path="/" component={DemoQuoteLandingPage} />
              {/* Cient app jobs */}
              <Route
                path="/quotes"
                render={(props) => <Job isProposition={true} {...props} />}
              />
              <Route
                path="/invoices"
                render={(props) => <Job isProposition={false} {...props} />}
              />
              {/* PDF version */}
              <Route path="/job_overview" component={JobOverviewPage} />
              {/* Mobile version */}
              <Route
                path="/job_overview_preview"
                component={JobOverviewPreviewPage}
              />
              <Route
                path="/subsidised_finance_information_overview"
                component={SubsidisedFinanceInformationOverviewPage}
              />
            </Switch>
          </JssProvider>
        </MuiThemeProvider>
      </Router>
    );
  }
}

export default App;
