import React from 'react';
import moment from 'moment';
var JobOverviewTimelineEvent = function (_a) {
    var children = _a.children, icon = _a.icon, name = _a.name, timestamp = _a.timestamp;
    return (React.createElement("div", { className: "job-overview-timeline-event" },
        React.createElement("div", { className: "job-overview-timeline-event-summary" },
            React.createElement("span", { className: "icon-wrapper" }, icon),
            React.createElement("div", { className: "job-overview-timeline-body" },
                timestamp && (React.createElement("span", { className: "timestamp" }, moment(timestamp).format('DD MMM YYYY HH:mm:ss z'))),
                React.createElement("span", { className: "job-overview-timeline-event-summary-content" },
                    React.createElement("span", { className: "name" }, name),
                    children)))));
};
export default JobOverviewTimelineEvent;
