import React, { useCallback } from 'react';
import MaterialUiSlider from '@material-ui/core/Slider';
import NumberFormat from 'react-number-format';
import './Slider.sass';
var Slider = function (_a) {
    var description = _a.description, _b = _a.hideCurrentValueLabel, hideCurrentValueLabel = _b === void 0 ? false : _b, _c = _a.hideLabels, hideLabels = _c === void 0 ? false : _c, inputValue = _a.inputValue, inputLabel = _a.inputLabel, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, minSliderValue = _a.minSliderValue, maxSliderValue = _a.maxSliderValue, onInputBlur = _a.onInputBlur, onInputChange = _a.onInputChange, onSliderChange = _a.onSliderChange, _e = _a.sliderStep, sliderStep = _e === void 0 ? null : _e, sliderLabelFormat = _a.sliderLabelFormat, sliderValue = _a.sliderValue;
    var sliderLabel = useCallback(function (val) {
        return sliderLabelFormat ? sliderLabelFormat(val) : val;
    }, [sliderLabelFormat]);
    return (React.createElement("div", { className: "slider-wrapper" },
        React.createElement("div", { className: "slider-input-and-description" },
            description && React.createElement("span", null, description),
            inputValue !== undefined && !!onInputChange && (React.createElement("div", { className: "input-control" },
                inputLabel && (React.createElement("span", { className: "input-control-label" }, inputLabel)),
                React.createElement(NumberFormat, { value: inputValue / 100, thousandSeparator: true, onValueChange: function (values) {
                        if (values && (values.floatValue || values.floatValue === 0)) {
                            onInputChange(values.floatValue * 100);
                        }
                    }, onBlur: function () { return onInputBlur && onInputBlur(); }, disabled: isDisabled })))),
        React.createElement("div", { className: "slider-control" },
            React.createElement(MaterialUiSlider, { value: sliderValue, step: sliderStep, min: minSliderValue, max: maxSliderValue, onChange: function (e, newValue) { return onSliderChange(newValue); }, valueLabelDisplay: hideCurrentValueLabel ? 'off' : 'auto', valueLabelFormat: sliderLabelFormat, disabled: isDisabled }),
            !hideLabels && (React.createElement("div", { className: "slider-labels" },
                React.createElement("span", { className: "slider-control-label min" }, sliderLabel(minSliderValue)),
                React.createElement("span", { className: "slider-control-label max" }, sliderLabel(maxSliderValue)))))));
};
export default Slider;
