import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import React, { useMemo } from 'react';
import Button from '../button/Button';
var FinanceOptionScreen = function (_a) {
    var className = _a.className, footerDisclaimerContent = _a.footerDisclaimerContent, backgroundImagePath = _a.backgroundImagePath, backgroundImageOverlayColour = _a.backgroundImageOverlayColour, onNavigateBack = _a.onNavigateBack, children = _a.children;
    var backButton = useMemo(function () {
        return (React.createElement("div", { className: "back-button-container" },
            React.createElement(Button, { onClick: onNavigateBack, iconBefore: faAngleLeft }, "Back")));
    }, [onNavigateBack]);
    console.log(backgroundImageOverlayColour);
    return (React.createElement("div", { className: "finance-option-screen " + (className ? className : '') },
        backgroundImagePath && (React.createElement("div", { className: "background-image", style: {
                backgroundImage: "url(" + backgroundImagePath + ")",
            } })),
        backgroundImageOverlayColour && (React.createElement("div", { className: "background-image-overlay", style: {
                backgroundColor: backgroundImageOverlayColour,
            } })),
        React.createElement("div", { className: "finance-option-screen-outer" },
            onNavigateBack && backButton,
            React.createElement("div", { className: "finance-option-screen-inner" },
                React.createElement("div", { className: "content-wrapper" },
                    React.createElement("div", null, children)),
                footerDisclaimerContent && (React.createElement("div", { className: "footer-disclaimer" },
                    React.createElement("div", { className: "footer-disclaimer-content" }, footerDisclaimerContent)))))));
};
export default FinanceOptionScreen;
