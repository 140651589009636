import React from 'react';
import { Modal } from '../modal';
var LenderHelpModal = function (_a) {
    var open = _a.open, onClose = _a.onClose;
    return (React.createElement(Modal, { className: "lender-help-modal", isOpen: !!open, onClose: function () { return onClose(); } },
        React.createElement("div", { className: "content-wrapper" },
            React.createElement("div", { className: "information-detail" },
                React.createElement("span", null, "Email address: "),
                React.createElement("a", { href: "mailto:support@duologi.com" }, "support@duologi.com")),
            React.createElement("div", { className: "information-detail" },
                React.createElement("span", null, "Phone number: "),
                React.createElement("a", { href: "tel:+443455211881" }, "0345 5211 881"),
                " then choose the relevant option"),
            React.createElement("div", { className: "information-detail" },
                React.createElement("span", null, "Opening hours: "),
                React.createElement("span", null, "Monday - Friday 9am - 5:30pm")))));
};
export default LenderHelpModal;
