import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/react';

import { Button } from '@payaca/components/button';
import { MiniLoader } from '@payaca/components';

import './AddPaymentMethodForm.sass';

const useStyles = makeStyles((theme) => ({
  subscriptionButton: {
    margin: 0,
    marginTop: '1rem',
  },
  cancelButton: {},
  getResultsButton: {
    width: 150,
    height: 50,
    backgroundColor: '#fde8bc',
    color: '#000',
    '&:hover': {
      backgroundColor: '#f3d9a2',
    },
  },
  breakdown: {
    marginTop: 20,
    fontSize: 14,
  },
}));

const AddPaymentMethodForm = ({
  onComplete,
  disabled,
  apiSubmitting,
  clientSecret,
  customerName,
  customerEmail,
  amount,
  companyName,
  onComponentRenderChange,
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const [isLoadingPaymentRequest, setIsLoadingPaymentRequest] = useState(true);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [formComplete, setFormComplete] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: 'gbp',
        total: {
          label: companyName,
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        console.log(result);
        if (result) {
          setPaymentRequest(pr);
        }
        setIsLoadingPaymentRequest(false);
      });
    }
  }, [stripe]);

  const [submitting, setSubmittingPayment] = React.useState(false);

  let applePayButton = null;

  if (paymentRequest) {
    paymentRequest.on('paymentmethod', async (ev) => {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      const { error: confirmError } = await stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      );

      if (confirmError) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        console.log(confirmError);
        Sentry.captureException(confirmError);
        onComponentRenderChange && onComponentRenderChange();
        ev.complete('fail');
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        ev.complete('success');

        // Let Stripe.js handle the rest of the payment flow.
        const result = await stripe.confirmCardPayment(clientSecret);

        if (result.error) {
          // The payment failed -- ask your customer for a new payment method.
          Sentry.captureException(result.error);
          setError(result.error);
          onComponentRenderChange && onComponentRenderChange();
        } else {
          // The payment has succeeded.
          onComplete(result);
        }
      }
    });

    applePayButton = (
      <div style={disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
        <PaymentRequestButtonElement
          options={{ paymentRequest, theme: 'dark' }}
        />
      </div>
    );
    onComponentRenderChange && onComponentRenderChange();
  }

  const checkFormIsComplete = (event) => {
    if (event.complete) {
      setFormComplete(true);
    }
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    setSubmittingPayment(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: customerName,
        },
      },
      receipt_email: customerEmail,
    });

    setSubmittingPayment(false);

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      setError(result.error.message);
      Sentry.captureException(result.error);
      onComponentRenderChange && onComponentRenderChange();
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === 'succeeded') {
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.

        onComplete(result);
      }
    }
  };

  if (isLoadingPaymentRequest) {
    return (
      <div className="loader-wrapper">
        <MiniLoader />
      </div>
    );
  }

  return (
    <div className="checkout">
      {applePayButton && <div>{applePayButton}</div>}
      <p>
        {applePayButton
          ? 'Or enter your credit card details to make a payment'
          : 'Enter your credit card details to make a payment'}
      </p>
      <form onSubmit={handleSubmit}>
        <CardElement onChange={(event) => checkFormIsComplete(event)} />
        <div className="button-container">
          <Button
            isProcessing={submitting || apiSubmitting}
            isDisabled={
              submitting || apiSubmitting || disabled || !formComplete
            }
            className={classes.subscriptionButton}
            type="submit"
          >
            Pay now
          </Button>
        </div>
        {error && <div>{error}</div>}
      </form>
    </div>
  );
};

export default AddPaymentMethodForm;
