import React, { useMemo } from 'react';
import { CurrencyField, LabelValuePair } from '@payaca/components';
import { Button } from '@payaca/components/button';
import './BacsPaymentControl.sass';
var BacsPaymentControl = function (_a) {
    var amountDue = _a.amountDue, bacsAmount = _a.bacsAmount, bacsDetails = _a.bacsDetails, jobTotalAmountDue = _a.jobTotalAmountDue, onBacsAmountChange = _a.onBacsAmountChange, onPayment = _a.onPayment;
    var isExceedingAmountDue = useMemo(function () {
        return !!bacsAmount && bacsAmount > amountDue;
    }, [amountDue, bacsAmount]);
    var isExceedingJobTotal = useMemo(function () {
        return !!bacsAmount && bacsAmount > jobTotalAmountDue;
    }, [bacsAmount, jobTotalAmountDue]);
    var amountExceedingMessage = useMemo(function () {
        if (isExceedingAmountDue && isExceedingJobTotal) {
            return 'You are confirming a payment that will exceed the amount due now and total job amount';
        }
        else if (isExceedingAmountDue) {
            return 'You are confirming a payment that will exceed the amount due now';
        }
        else if (isExceedingJobTotal) {
            return 'You are confirming a payment that will exceed the total job amount';
        }
        else {
            return null;
        }
    }, [isExceedingAmountDue, isExceedingJobTotal]);
    return (React.createElement("div", { className: "payment-control bacs-payment-control" },
        React.createElement(LabelValuePair, { label: "Account name", value: bacsDetails.accountName }),
        React.createElement(LabelValuePair, { label: "Account number", value: bacsDetails.accountNumber }),
        React.createElement(LabelValuePair, { label: "Sort code", value: bacsDetails.sortCode }),
        React.createElement("div", { className: "input-and-button-wrapper" },
            React.createElement(CurrencyField, { name: "bacsAmount", value: bacsAmount, onChange: function (value) { return onBacsAmountChange(value.bacsAmount); } }),
            React.createElement("div", { className: "button-container" },
                React.createElement(Button, { onClick: function () { return onPayment({ type: 'bacsTransfer' }); }, isDisabled: !bacsAmount || bacsAmount <= 0 }, "I have made a payment"))),
        amountExceedingMessage && (React.createElement("div", { className: "error amount-due-exceeding-message" }, amountExceedingMessage))));
};
export default BacsPaymentControl;
